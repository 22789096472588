.categories_buttons_container {
  width: 40%;
}

.categories_container {
  width: 100%;
}

.categories_container .categories_heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.26px;
  color: #343538;
}

.add_my_service__button {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
}

@media (max-width: 435px) {
  .add_my_service__button {
    flex-direction: column !important;
    justify-content: start;
  }
}
