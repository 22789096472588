.order_summary__form {
  width: 20rem;
}

.order_summary .form_title,
.reason {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.41px;
  text-align: left;
  color: #1a1b1e;
}

.cart_table .css-1ex1afd-MuiTableCell-root,
.cart_table .css-9pg0n1-MuiTableCell-root {
  border: none !important;
}

.cart_prod_img__box {
  width: 6.25rem;
  height: 6.25rem;
}

.cart_prod_img__box .cart_prod_img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  object-position: bottom;
}

.cart_prod_title,
.details_of_cart,
.pricing__section h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 29.11px;
  text-align: left;
  color: #909094;
}

.pricing__section h3 .amount {
  font-size: 28px;
  font-weight: 700;
  line-height: 34.66px;
  text-align: left;
  color: #000000;
}

.reason_details {
  width: 70%;
}

.cart_table .css-1ygcj2i-MuiTableCell-root {
  padding: 16px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .order_summary__form {
    width: 18rem;
  }

  .cart_prod_img__box {
    width: 5.25rem;
    height: 5.25rem;
  }

  .cart_prod_title,
  .details_of_cart,
  .pricing__section h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 29.11px;
    text-align: left;
    color: #909094;
  }

  .reason_details {
    width: 90%;
  }
}

@media (max-width: 568px) {
  .cart_table .css-1ygcj2i-MuiTableCell-root {
    padding: 4px 1px;
    font-size: 12px;
  }

  .cart_prod_img__box {
    width: 2.55rem;
    height: 2.55rem;
  }

  .cart_prod_title,
  .details_of_cart {
    font-size: 12px;
    font-weight: 500;
    line-height: 18.11px;
    text-align: left;
    color: #909094;
  }
  .pricing__section h3 {
    font-size: 16px;
  }
  .pricing__section h3 .amount {
    font-size: 26px;
  }
}

@media (max-width: 456px) {
  .order_summary__form {
    width: 100%;
  }
  .reason_details {
    width: 100%;
  }
}
