.profile_setup__last {
  width: 100%;
  height: auto;
  margin-top: 75px;
  background-color: rgb(253, 251, 255);
}

.setup__img_aside {
  width: 40%;
  height: 80rem;
}

.setup__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.setup_form {
  width: 60%;
  height: auto;
  margin-top: 3.5rem;
}

.labels {
  color: #1a1b1e;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.09px;
}

.file-upload-container {
  width: auto;
  /* border: 1px dashed #ccc; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.file-upload-header {
  display: flex;
  align-items: center;
}

.file-upload-header h3 {
  font-size: 18px;
  margin: 0;
}

.file-upload-header .highlight {
  font-weight: bold;
}

.upload_image .uploaded_file {
  text-decoration: none !important;
}

.file-drop-zone {
  border: 2px dashed #ccc;
  border-radius: 8px;
  width: auto;
  height: auto;
  position: relative;
}

.upload_para {
  font-size: 14px;
  line-height: 16.24px;
  font-weight: 400;
  text-align: start;
}

.file-drop-text {
  text-align: center;
}

.drag-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.upload-button:hover {
  background-color: #0056b3;
}

.social_link_name {
  width: 13rem;
}

@media (max-width: 992px) {
  .setup_form {
    width: 50%;
    height: auto;
    margin-top: 3.5rem;
  }

  .setup__img_aside {
    width: 50%;
    height: 83rem;
  }
}

@media (max-width: 768px) {
  .setup_form {
    width: 100%;
    height: auto;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
}
