.service_provider__container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 7rem 0;
}

.bidder_profile_button__bidder_review_button .bidder_profile__button {
  border-radius: 25px 0 0 25px;
  width: 25rem;
  height: 2.5rem;
  text-transform: none;
  font-size: 16px;
}

.bidder_profile_button__bidder_review_button .bidder_review_button {
  border-radius: 0 25px 25px 0;
  width: 25rem;
  height: 2.5rem;
  text-transform: none;
  font-size: 16px;
}
