@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
}

.account-reviews_container {
  width: 100%;
  height: auto;
}

.reviews-heading {
  font-size: 32px;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
}

.reviews-progress-bar-container {
  width: 100%;
  height: auto;
  border: 1.5px solid rgba(194, 199, 207, 1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  justify-items: center;
}
