@font-face {
  font-family: "Gilroy-regular";
  src: url(.././../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
}

.download__container {
  width: 100%;
  height: 25.3rem;
  background-color: rgba(255, 185, 95, 1);
  overflow: hidden;
}

.download__headings-and-buttons {
  width: 39rem;
}

.download__headings-and-buttons .download__heading {
  text-decoration: none !important;
  font-size: 64px;
  font-weight: 600;
  line-height: 77.38px;
  text-align: left;
  color: rgba(19, 101, 175, 1);
}

.download__subheading {
  width: 36rem;
  overflow: hidden;
  font-size: 16px;
  line-height: 18.83px;
  color: rgba(42, 23, 0, 1);
  text-transform: none !important;
  font-weight: 500;
}

.download_img {
  background-image: url("/public/images/home/newslater/download_img.png");
  background-size: 31.25rem;
  background-position: center;
  width: 30rem;
  height: auto;
  overflow: hidden;
  margin-bottom: -25px;
  transform: scaleX(-1);
}

@media (max-width: 576px) {
  .download__headings-and-buttons {
    width: auto !important;
    padding: 0 10px !important;
  }
  .download__headings-and-buttons .download__heading {
    font-size: 45px !important;
    line-height: 60.38px;
    text-align: center !important;
  }
  /* .download__headings-and-buttons .download__subheading {
  } */
}

@media (max-width: 768px) {
  .download__headings-and-buttons {
    width: auto !important;
    padding: 0 5rem;
  }

  .download__headings-and-buttons .download__heading {
    font-size: 55px;
    text-align: center !important;
    padding-left: 20px;
  }

  .download__headings-and-buttons .download__subheading {
    width: auto !important;
    font-size: 14px !important;
    text-align: center !important;
  }

  .download_img {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .download__headings-and-buttons .download__heading {
    text-decoration: none !important;
    font-size: 60px;
  }

  .download__subheading {
    width: 30rem;
  }

  .download_img {
    background-size: 28.25rem;
  }
  .download_img {
    width: 28rem !important;
  }
}
