@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.recent_auction__carousel .carousel {
  padding: 20px;
  background: white;
  border-radius: 10px;
  margin: 15px;
}

.recent_auction__carousel .react-multi-carousel-dot button {
  border: 1px solid grey;
}

.recent_auction__carousel .carousel-auction-title {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.recent_auction__carousel .auction-time {
  font-size: 14px;
  font-weight: bold;
  color: rgba(106, 125, 159, 1);
  text-decoration: none;
}

.recent_auction__carousel .carousel-aution-cusName {
  font-weight: bold;
  font-size: 17px;
}

.recent_auction__carousel .carousel-auction-info .info-icon,
.recent_auction__carousel .carousel-aution-location .map-icon,
.recent_auction__carousel .carousel-aution-date .calender-icon {
  width: 18.75px;
  height: 18.75px;
  color: #6a7d9f;
}

.recent_auction__carousel .carousel-text {
  color: #626f86;
  font-size: 16px;
}

.recent_auction__carousel .carousel-auction-id {
  color: rgba(106, 125, 159, 1);
  font-size: 17px;
}

.recent_auction__carousel .carousel-aution-budget {
  padding: 4px 10px;
  color: rgba(42, 153, 40, 1);
  background-color: rgba(232, 248, 225, 1);
  font-size: 16px;
}

.recent_auction__carousel .carousel-auction-bids,
.recent_auction__carousel .carousel-auction-timeLeft {
  background-color: rgba(236, 240, 255, 1);
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .recent_auction__carousel .carousel {
    padding: 16px;
    background: white;
    border-radius: 10px;
    margin: 15px;
  }

  .recent_auction__carousel .carousel-auction-info .info-icon,
  .recent_auction__carousel .carousel-aution-location .map-icon,
  .recent_auction__carousel .carousel-aution-date .calender-icon {
    width: 16px;
    height: 16px;
    color: #6a7d9f;
    color: rgba(98, 111, 134, 1);
  }

  .recent_auction__carousel .carousel-text {
    color: #626f86;
    font-size: 13.5px;
  }

  .recent_auction__carousel .carousel-auction-bids,
  .recent_auction__carousel .carousel-auction-timeLeft {
    background-color: rgba(236, 240, 255, 1);
    font-size: 11.5px;
    font-weight: 500;
  }

  .recent_auction__carousel .carousel-aution-budget {
    padding: 4px 10px;
    color: rgba(42, 153, 40, 1);
    background-color: rgba(232, 248, 225, 1);
    font-size: 13.5px;
  }
}

@media (max-width: 768px) {
  .recent_auction__carousel .react-multi-carousel-dot button {
    width: 10px;
    height: 10px;
    border: 1px solid grey;
  }
  .recent_auction__carousel .carousel-auction-title {
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }

  .recent_auction__carousel .auction-time {
    font-size: 12px;
    font-weight: bold;
    color: rgba(106, 125, 159, 1);
    text-decoration: none;
  }

  .recent_auction__carousel .carousel-auction-info .info-icon,
  .recent_auction__carousel .carousel-aution-location .map-icon,
  .recent_auction__carousel .carousel-aution-date .calender-icon {
    width: 16px;
    height: 16px;
    color: #6a7d9f;
    color: rgba(98, 111, 134, 1);
  }

  .recent_auction__carousel .carousel-text {
    color: #626f86;
    font-size: 12.5px;
  }

  .recent_auction__carousel .carousel-auction-bids,
  .recent_auction__carousel .carousel-auction-timeLeft {
    background-color: rgba(236, 240, 255, 1);
    font-size: 10.5px;
    font-weight: 500;
  }

  .recent_auction__carousel .carousel-aution-budget {
    padding: 4px 10px;
    color: rgba(42, 153, 40, 1);
    background-color: rgba(232, 248, 225, 1);
    font-size: 12.5px;
  }
}

@media (max-width: 576px) {
  .recent_auction__carousel .react-multi-carousel-dot button {
    width: 9px;
    height: 9px;
    border: 1px solid grey;
  }
}

@media (max-width: 400px) {
  .recent_auction__carousel .carousel {
    padding: 20px;
    background: white;
    border-radius: 10px;
    margin: 0;
  }
}
