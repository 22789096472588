@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.account_contact h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.63px;
  letter-spacing: 0%;
  color: #000;
}

.contact-info-container {
  width: 100%;
  height: auto;
  background-color: rgba(244, 243, 247, 1);
}

.account_info_box .account_info_head {
  color: rgba(106, 125, 159, 1);
  font-weight: 600;
  font-size: 16px;
  line-height: 18.83px;
  letter-spacing: 0%;
}
.account_info_box .account_info_text {
  font-weight: 500;
  font-size: 20px;
  line-height: 23.54px;
  letter-spacing: 0%;
  color: rgba(39, 94, 167, 1);
}

.contact-info-container .account_info_label {
  font-weight: 600;
  font-size: 24px;
  line-height: 28.63px;
  letter-spacing: 0%;
}

@media (max-width: 756px) {
  .account_contact h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24.09px;
    letter-spacing: 0%;
    color: #000;
  }

  .contact-info-container .account_info_label {
    font-weight: 600;
    font-size: 20px;
    line-height: 26.63px;
    letter-spacing: 0%;
  }
}

@media (max-width: 576px) {
  .contact-info-container .account_info_label {
    font-weight: 600;
    font-size: 16px;
    line-height: 18.63px;
    letter-spacing: 0%;
  }

  .account_info_box .account_info_head {
    color: rgba(106, 125, 159, 1);
    font-weight: 600;
    font-size: 14px;
    line-height: 16.83px;
    letter-spacing: 0%;
  }
  .account_info_box .account_info_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 18.54px;
    letter-spacing: 0%;
    color: rgba(39, 94, 167, 1);
  }
}
