/* Certification.css */

/* Styling for the certificates slider */
.certificates_slider {
  width: clamp(12rem, 40vw, 19.5rem);
  aspect-ratio: 1.2 / 1;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.certificates_slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Certificates container styling */
.certificates-container {
  display: grid;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

/* Positioning and styling for the image container */
.image-container {
  position: relative;
  display: inline-block;
  margin: 5px;
}

/* Styling for the delete icon */
.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  padding: 5px;
  display: block; /* Always visible */
  z-index: 10; /* Ensure it's on top of the image */
}

/* Styling for the uploaded image */
.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Large screens (above 992px) - 3 images per row */
@media (min-width: 993px) {
  .certificates-container {
    grid-template-columns: repeat(3, 1fr); /* 3 images per row */
  }
}

/* Medium screens (between 577px and 992px) - 2 images per row */
@media (max-width: 992px) and (min-width: 577px) {
  .certificates-container {
    grid-template-columns: repeat(2, 1fr); /* 2 images per row */
  }
}

/* Small screens (below 577px) - 1 image per row */
@media (max-width: 576px) {
  .certificates-container {
    grid-template-columns: repeat(2, 1fr); /* 1 image per row */
  }

  .certificates_slider {
    width: 100%;
  }
  .certificate-item img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
}

/* For images inside the dialog - Ensure 3 images per row on large screens */
.dialog-image-grid {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(
    3,
    1fr
  ); /* 3 images per row on larger screens */
}

/* Medium screens - 2 images per row */
@media (max-width: 992px) {
  .dialog-image-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 images per row */
  }
}

/* Small screens - 1 image per row */
@media (max-width: 576px) {
  .dialog-image-grid {
    grid-template-columns: 1fr; /* 1 image per row */
  }
}
