.chatting_box__container {
  width: 100%;
  height: 100%;

  position: relative;
}

.chatting_box__container .active_chats_chatting {
  width: 100%;
  height: 61px;
  background-color: #fff;
  position: absolute;
  top: 75px;
}

.active_chats__name .active_chatter__name {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.83px;
  text-align: left;
  color: #000;
}

.active_chats__name .active_chatter_isonline {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.48px;
  text-align: left;
  color: #2a9928;
}

.chatting_box__container .message_typing__area__container {
  position: absolute;
  bottom: 0rem;
  width: 100%;
  height: 61px;
  background-color: #fff;
}

.message_typing__area__container .message_box {
  width: 60%;
}
