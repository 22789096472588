.product_cart__container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 7rem 75px;
}

.heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 31px;
  text-align: left;
  color: #000000;
}

.product_cart__steps {
  margin-top: 3rem;
}

.product_cart__steps .css-z7uhs0-MuiStepConnector-line {
  display: block;
  border-color: #bdbdbd;
  border-top-style: solid;
  border-top-width: 5px;
  border-radius: 5px;
  z-index: 0 !important;
}

.product_cart__steps .css-zpcwqm-MuiStepConnector-root {
  top: 12px;
  left: calc(-91% + 20px);
  right: calc(9% + 20px);
  z-index: 0 !important;
}

.product_cart__steps
  .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: -55px;
}

.product_cart__steps
  .css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  padding-right: 0;
  z-index: 100;
  border: 5px solid #fff;
  border-radius: 50%;
  background: #fff;
}

.product_cart__steps .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: #b6b6b6;
}

@media (max-width: 1024px) {
  .product_cart__container {
    padding: 4rem 3rem 75px;
  }
}
@media (max-width: 768px) {
  .product_cart__container {
    padding: 4rem 2.5rem 75px;
  }
  .product_cart__steps
    .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: -75px;
  }
}

@media (max-width: 568px) {
  .product_cart__container {
    padding: 4rem 1rem 75px !important;
  }
  .product_cart__steps {
    margin-top: 4.5rem;
  }
  .product_cart__steps
    .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: -55px;
  }
}
@media (max-width: 396px) {
  .product_cart__steps
    .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: -75px;
  }
}
