@font-face {
  font-family: "Gilroy-regular";
  src: url(.././../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.cutomer_orders__container .search-bar {
  border: 1px solid rgba(106, 125, 159, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 2px; /* Adjust padding as necessary */
}

.cutomer_orders__container .search-bar input {
  border: none;
  outline: none;
  width: 100%;
  padding: 8px; /* Adjust padding as necessary */
  box-sizing: border-box;
}

.customer-name {
  font-size: 20px;
  line-height: 23.54px;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
}

.order_time {
  font-size: 14px;
  font-weight: 500;
  color: rgba(106, 125, 159, 1);
}

.order-id {
  font-size: 18px;
  font-weight: 500;
  text-transform: none;
  line-height: 21.19px;
  color: rgba(106, 125, 159, 1);
}

.order-price {
  color: rgba(42, 153, 40, 1);
  font-size: 20px;
  font-weight: 600;
  line-height: 23.86px;
}

.content {
  color: rgba(106, 125, 159, 1);
  font-size: 18px;
  font-weight: 500;
  line-height: 21.19px;
  text-align: left;
}

.order-status {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.83px;
  padding: 5px 10px 5px 10px;
  gap: 10px;
  opacity: 0px;
  text-align: center;
}

.order-status__in_progress {
  background-color: rgba(242, 239, 207, 1);
  color: rgba(137, 132, 0, 1);
}

.order-status__completed {
  background-color: #e8fbe8;
  color: #2a9928;
}

.cutomer_orders__container .icons {
  width: 19px;
  height: 19px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.pagination li {
  list-style: none;
  margin: 0 5px;
  width: 31px;
  height: 32px;
  text-align: center;
  padding: 0px, 12px, 0px, 12px;
}

.pagination li a {
  text-decoration: none;
  color: rgba(0, 27, 61, 1);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.pagination li.active a,
.pagination li.active {
  background-color: rgba(208, 223, 255, 1);
  font-weight: bold;
  border-radius: 4px;
}

@media (max-width: 992px) {
  .content {
    color: rgba(106, 125, 159, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 18.19px;
    text-align: left;
  }

  .order_time {
    font-size: 14px;
  }

  .cutomer_orders__container .icons {
    width: 17px;
    height: 17px;
  }
}

@media (max-width: 576px) {
  .customer-name {
    font-size: 18px;
    line-height: 23.54px;
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
  }

  .order_time {
    font-size: 12px;
    font-weight: 500;
    color: rgba(106, 125, 159, 1);
  }

  .order-id {
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
    line-height: 21.19px;
    color: rgba(106, 125, 159, 1);
  }

  .content {
    color: rgba(106, 125, 159, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 16.19px;
    text-align: left;
  }
}
