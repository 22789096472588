.analytics-box {
  width: 100%;
  /* padding: 2rem 7rem 2rem 7rem; */
  margin-bottom: 1rem;
}

.heading {
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
}

.order-recived {
  font-weight: 600;
  color: rgba(19, 101, 175, 1);
}

.order_filter__text {
}

.download-chart {
  text-transform: none;
  font-size: 20px;
}
