@font-face {
    font-family: 'Gilroy-regular';
    src:url(.././../../../public/fonts/Gilroy-Regular.ttf) format('truetype');
}

.gilroy-regular{
    font-family:  'Gilroy-regular' !important;
}

.overlay{
    width: 100%;
    height:100%;
    background:rgba(0,0,0,.2);
    position:absolute;
    z-index:1;
    top:0px;
    padding:0px;
}

.title{
    font-size: 32px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height:normal;
    color:#FFF;
    font-family:  'Gilroy-regular' !important;
}

.subtitle{
    margin-top:13px !important;
    color:#FFF;
    font-size:16px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
    font-family:  'Gilroy-regular' !important;
}


@media(max-width: 768px)
{
    .title{
        font-size: 25px !important;
    }
}