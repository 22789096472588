.anlytics__heading {
  font-size: 45px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
}

@media (max-width: 768px) {
  .anlytics__heading {
    font-size: 40px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
  }
}

@media (max-width: 576px) {
  .anlytics__heading {
    font-size: 30px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
  }
}
