@font-face {
  font-family: "Gilroy-regular";
  src: url(../.././../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy-regular {
  font-family: "Gilroy-regular" !important;
}

.automobile_carousel .react-multiple-carousel__arrow--left {
  left: 0 !important;
  top: 29% !important;
  background-color: #6a7d9f !important;
  border: 3px solid #fdfbff !important;
}

.automobile_carousel .react-multiple-carousel__arrow--right {
  right: 0 !important;
  top: 29% !important;
  background-color: #6a7d9f !important;
  border: 3px solid #fdfbff !important;
}

.automobile_service .item_image {
  width: 210px;
  height: 170px;
  object-fit: cover;
  object-position: top;
}

/* style={{ color: "#1365AF" }} */

@media (max-width: 320px) {
  .automobile_service .item_image {
    width: 180px !important;
    height: 140px !important;
  }
}
