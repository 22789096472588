.progress-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.88px;
  letter-spacing: 0.17000000178813934px;
  width: 3rem;
}

.stars-no {
  color: rgba(0, 0, 0, 0.6);
}

.progress-percent {
  color: rgba(0, 0, 0, 0.38);
  width: 2.3rem;
}
