@font-face {
  font-family: "Gilroy-regular";
  src: url(.././../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy-regular {
  font-family: "Gilroy-regular" !important;
}

/* .react-multiple-carousel__arrow--left,.react-multiple-carousel__arrow--right{
    background-color: #6A7D9F;
    z-index:111 !important;
    border:3px solid #FDFBFF;
} */

/* .react-multiple-carousel__arrow--left,.react-multiple-carousel__arrow--right:hover{
    background-color: #6A7D9F;
} */

.react-multi-carousel-dot-list {
  bottom: 10px !important;
}

.custom-arrow {
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.left-arrow {
  margin-right: 10px;
}

.right-arrow {
  margin-left: 10px;
}

.quote {
  width: 94px;
  height: 94px;
  position: absolute;
  right: 0px;
  top: 50px;
  z-index: 1;
  /* border: 1px solid red; */
}

.no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

@media (max-width: 768px) {
  .quote {
    width: 50px;
    height: 50px;
    right: 2px;
    top: 40px;
    z-index: 1;
  }
}

@media (max-width: 342px) {
  .quote {
    width: 50px;
    height: 50px;
    right: 2px;
    top: 73px;
    z-index: 1;
  }
}
