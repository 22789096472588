.categories_services__container {
  width: 100%;
}
/* .categories_services__container .categories_heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.26px;
  color: #343538;
} */

.services_img__container {
  width: 100%;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0.62px 2.75px rgba(39, 53, 141, 0.02);
}
.services__img {
  width: 100%;
  height: 8rem;
  object-fit: cover;
  object-position: top;
}

.result-content__box {
  border-radius: 4px 4px 0 0;
}

.categories--title {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
}

.checker {
  background-color: rgba(244, 243, 247, 1);
  padding: 0.1px 5px 0.1px 5px;
  border-radius: 4px;
  text-align: center;
  color: #172b4d;
  font-size: 12px;
  font-weight: 500;
  line-height: 18.83px;
}

.company__address {
  font-size: 16px !important;
  color: #6a7d9f;
  line-height: 23.54px;
  font-weight: 500;
}

.ratings__outof {
  font-size: 24px;
  color: rgb(0, 0, 0);
  line-height: 33.4px;
}

.categories__reviews {
  color: rgba(106, 125, 159, 1);
  font-size: 16px;
  font-weight: 500;
}

.service-giving {
  color: rgba(23, 43, 77, 1);
  background-color: rgba(214, 236, 255, 1);
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  padding: 5px 4px 4px;
  line-height: 18.83px;
}

.service-details {
  /* width: 65%; */
  /* height: 20px; */
  color: rgba(106, 125, 159, 1);
  font-size: 14px;
  line-height: 16.83px;
  /* overflow: hidden; */
}

.service-pare-expand {
  font-size: 16px;
  color: rgba(74, 123, 157, 1);
  font-weight: 500;
  line-height: 18.83px;
  border: none;
  background: none;
}

.categories__open--time,
.categories__response {
  color: rgba(66, 71, 78, 1);
  font-size: 16px;
  line-height: 18.83px;
  font-weight: 500;
  padding: 10px;
  background-color: rgba(244, 243, 247, 1);
  border-radius: 4px;
}

@media (max-width: 992px) {
  .categories__open--time,
  .categories__response {
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .company__address {
    font-size: 16px;
  }
}
