@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.my-account-container {
  width: 100%;
  height: auto;
  margin-top: 75px;
  padding: 4rem 6.5rem 0 6.5rem;
  margin-bottom: 1rem;
}

.profile-button--reviews-sent-button .profile-button {
  border-radius: 25px 0 0 25px;
  width: 22rem;
}

.profile-button--reviews-sent-button .reviews-sent-button {
  border-radius: 0 25px 25px 0;
  width: 22rem;
}

@media (max-width: 992px) {
  .my-account-container {
    padding: 75px 3.5rem 0px 3.5rem;
  }

  .seller_details_steps__container {
    width: 40%;
  }

  .business__profile_content {
    width: 100%;
  }
  .seller_profile__contents {
    gap: 1.4rem;
  }
}

@media (max-width: 568px) {
  .my-account-container {
    padding: 30px 0.8rem 0 0.8rem;
  }
}

@media (max-width: 435px) {
  .steps_headings {
    font-size: 20px;
  }
}
