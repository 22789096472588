@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}
.auctions-box {
  width: 100%;
  height: auto;
  margin-top: 75px;
  padding: 4rem 6.5rem;
}

.search-bar {
  width: 60%;
}

.search_bar .css-i4bv87-MuiSvgIcon-root {
  width: 2rem;
  height: 2rem;
}

input::placeholder {
  font-size: 16px;
}

.details-button--bids-button .details-button {
  border-radius: 25px 0 0 25px;
  width: 25rem;
  height: 2.5rem;
  text-transform: none;
  font-size: 16px;
}

.details-button--bids-button .bids-button {
  border-radius: 0 25px 25px 0;
  width: 25rem;
  height: 2.5rem;
  text-transform: none;
  font-size: 16px;
}

@media (max-width: 1200px) {
  .auctions-box {
    padding: 4rem 4.5rem;
  }
}

@media (max-width: 992px) {
  .search-bar {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .auctions-box {
    padding: 4rem 3rem;
  }
}

@media (max-width: 576px) {
  .auctions-box {
    padding: 4rem 2rem !important;
  }

  .search_bar .css-i4bv87-MuiSvgIcon-root {
    width: 1.3rem;
    height: 1.3rem;
  }
  .search-bar {
    width: 100%;
  }
}

@media (max-width: 420px) {
}
