.service_offered_box .service_offered_heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.63px;
  letter-spacing: 0%;
  color: #000;
}

@media (max-width: 756px) {
  .service_offered_box .service_offered_heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 24.09px;
    letter-spacing: 0%;
    color: #000;
  }
}
