.categories_buttons__container .categories_heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.26px;
  color: #343538;
}

.no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.css-w0qotz-MuiButtonBase-root-MuiButton-root {
  max-width: 16.6rem;
}
