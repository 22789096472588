@font-face {
  font-family: "Gilroy-regular";
  src: url(.././../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize;
}

.newslater__containter {
  width: 100%;
  height: auto;
  background-color: rgba(19, 101, 175, 1);
  margin: 0 auto;
}

.newslater__content {
  width: 46rem;
  height: auto;
}

.newlater__headings-container .newslater__heading {
  text-transform: none !important;
  font-size: 45px;
  font-weight: 600;
  line-height: 53.68px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

.horizontal__line {
  color: rgba(255, 255, 255, 1);
  width: 55px;
  border: 3px solid rgba(255, 255, 255, 1);
}

.newlater__headings-container .newslater__subheading {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  text-transform: none !important;
  padding: 15px 30px;
}

.subscribe__email .subscribe {
  width: 100%;
}

.form-control {
  border-radius: 3px !important;
  width: 100%;
  height: 44px !important;
}

.subscribe__email .email_to_subscribe {
  width: 30rem;
}

@media (max-width: 576px) {
  .newslater__content {
    width: auto !important;

    height: auto;
  }
  .subscribe__email .email_to_subscribe {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .newslater__content {
    width: 30rem;
    height: auto;
  }

  .newlater__headings-container .newslater__heading {
    text-transform: none !important;
    font-size: 35px;
    font-weight: 600;
    line-height: 53.68px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }

  .newlater__headings-container .newslater__subheading {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 500;
  }

  .subscribe__email .email_to_subscribe {
    width: 25rem;
  }
}
