.registration-container {
  width: 100%;
  height: auto;
  margin: 75px auto;
  display: flex;
  justify-content: center;
  padding: 90px 6.8rem 90px 6.8rem;

  background-color: rgb(253, 251, 255);
}

.form-details {
  width: 100%;
}

.registration_form_container {
  width: 100% !important;
}

@media (max-width: 992px) {
  .registration-container {
    padding: 60px 3.5rem 0px 3.5rem;
  }
}

@media (max-width: 768px) {
  .registration-container {
    padding: 60px 3.2rem 0px 3.2rem;
  }
}
@media (max-width: 435px) {
  .registration-container {
    width: 97%;
    justify-content: center;
    align-items: center;
    padding: 52px 10px 0px 7px;
  }
}
