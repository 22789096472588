.date-of-feedback {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.24px;
}

.reviews-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 9px;
  margin: 0 !important;
}

.reviews-box {
  width: 20rem;
  padding: 28px 26px;
  border: 1px solid rgba(194, 199, 207, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  margin: 0 auto;
}

.review-item {
  flex: 1 1 calc(33.333% - 16px);
  box-sizing: border-box;
  width: auto;
  margin: 0 auto;
}

.customer-reviews-comments {
  width: 100%;
  height: 15rem;
  overflow: scroll;
}
