.footer_container {
  background: rgba(26, 27, 30, 1);
  margin-top: 0;
  height: auto;
  position: relative;
  bottom: 0;
  margin-bottom: -27px;
}

.logo__social_links_content {
  padding: 120px 44px 88px;
  padding-left: 90px;

  /* paddingTop: "120px", paddingLeft: "90px"  */
}

@media (min-width: 576px) and (max-width: 768px) {
  .logo__social_links_content {
    padding: 120px 50px 0;
  }
}

@media (min-width: 435px) and (max-width: 576px) {
  .logo__social_links_content {
    padding: 120px 45px 0;
  }
}

@media (max-width: 435px) {
  .logo__social_links_content {
    padding: 70px 16px 0 !important;
    padding-left: 90px;

    /* paddingTop: "120px", paddingLeft: "90px"  */
  }
}
