@font-face {
  font-family: "Gilroy-regular";
  src: url(.././../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: url(.././../../../public/fonts/Gilroy-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url(.././../../../public/fonts/Gilroy-Medium.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body {
  font-family: Arial, sans-serif;
}

.css-ajt5ii-MuiButtonBase-root-MuiButton-root {
  background: rgba(9, 30, 66, 0.06) !important;
  text-transform: capitalize !important;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  /* color : #00468B; */
}

.website_logo {
  width: 100%;
  height: 100%;
}

.nav-icon-btn2 {
  background: #fa9b00 !important;
  font-size: 20px;
  font-weight: 500;
  /* line-height: normal; */
  color: white !important;
  text-transform: capitalize;
  text-align: center;
  /* font-family: Gilroy; */
  font-style: normal;
  line-height: 20px;
  /* 100% */
}
.nav-btn-icon {
  background: rgba(74, 123, 157, 1) !important;
  font-size: 20px;
  font-weight: 500;
  /* line-height: normal; */
  color: white !important;
  text-transform: capitalize;
  text-align: center;
  /* font-family: Gilroy; */
  font-style: normal;
  line-height: 20px;
  /* 100% */
}

.nav-btn {
  color: #d6e3ff !important;
  text-transform: capitalize;
  text-align: center;
  /* font-family: Gilroy; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.nav-link-underline {
  position: relative;
  margin-top: 3px;
}

.nav-link-underline::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: orange;
  border-radius: 15px 15px 0 0;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
.nav-link-underline:hover::after {
  transform: scaleX(1);
}

.main-box {
  width: 100%;
  background-color: #1365af;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  /* border: 1px solid red */
  /* box-shadow: 0px 0.618px 2.748px 0px rgba(39, 53, 141, 0.02), 0px 1.564px 6.95px 0px rgba(39, 53, 141, 0.02), 0px 3.19px 14.177px 0px rgba(39, 53, 141, 0.02), 0px 6.57px 29.201px 0px rgba(39, 53, 141, 0.03), 0px 18px 80px 0px rgba(39, 53, 141, 0.07); */
}

nav {
  width: 80%;
  height: 73px;
  margin-left: 10%;
  margin-right: 10%;
  background: #1365af;
  color: white;
  padding-top: 10px;
  margin-bottom: 0px !important;
  display: flex;
  /* flex-direction: row; */
  justify-content: start;
  gap: 20px;
  align-items: center;
  /* border: 2px solid red; */
  align-items: center;
}

nav .mainMenu {
  width: 95%;
  /* border: 2px solid blue; */
  display: flex;
  list-style: none;
  padding: 0px !important;
  margin-left: 13px;
  margin-top: 7px;
  align-items: center;
}

nav .mainMenu li {
  margin: 0px 5px !important;
  /* border: 2px solid yellow; */
  display: inline-block;
  /* padding: 15px; */
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  font-size: 10px;
  transition: 0.2s ease;
}

.breaker-li {
  display: block;
  width: 20% !important;
}

.cust-breaker-li {
  display: block;
  width: 20% !important;
}
.dashboard_breaker-li {
  display: block;
  width: 10% !important;
}

nav .openMenu {
  font-size: 2rem;
  margin: 20px;
  display: none;
  cursor: pointer;
}

.open-menu-icon {
  font-size: 40px !important;
  margin-top: -15px;
  display: none;
  cursor: pointer;
}

.close-menu-icon {
  font-size: 40px !important;
  display: none;
  cursor: pointer;
}
/* .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-fk7cwj-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 30px !important;
} */

.css-fk7cwj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 7.5px 0px 7.5px 0px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-dclvrq-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 31px !important;
}

.css-dclvrq-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  .MuiAutocomplete-input {
  padding: 2.5px 0px 2.5px 0px !important;
  font-size: 13px;
}

.css-fk7cwj-MuiAutocomplete-root .MuiAutocomplete-input {
  max-width: 80px !important;
}

nav .mainMenu .closeMenu,
.icons i {
  font-size: 2rem;
  display: none;
  cursor: pointer;
  color: white;
}

nav .logo {
  margin: 0px 0px 0px 40px;
  cursor: pointer;
  text-transform: uppercase;
}

nav .mainMenu .signup_link .signup-btn,
nav .mainMenu .login_link .login-btn {
  cursor: pointer;
}

.dashboarad {
  text-decoration: none;
  font-size: 13px;
}

.signup-model .button-box:hover,
.login-model .button-box:hover {
  background-color: #f7f7f7;
}

.signup-model {
  width: 9rem;
  background-color: #ffffff;
  top: 45px;
  right: 0;
  /* left: 0; */
}

.login-model {
  width: 9rem;
  background-color: #ffffff;
  top: 45px;
  right: 0;
}

@media (max-width: 1400px) {
  .breaker-li {
    display: block;
    width: 15% !important;
  }
  .cust-breaker-li {
    display: block;
    width: 24% !important;
  }
  .dashboard_breaker-li {
    display: block;
    width: 10% !important;
  }

  nav {
    width: 100%;
    margin-left: 15%;
    margin-right: 5%;
    background: #1365af;
    padding-top: 10px;
    margin-bottom: 0px !important;
    display: flex;
    /* flex-direction: row; */
    justify-content: start;
    align-items: center;
    /* border: 2px solid red; */
  }
}

@media (max-width: 1200px) {
  .breaker-li {
    display: block;
    width: 15% !important;
  }

  .cust-breaker-li {
    display: block;
    width: 15% !important;
  }

  .dashboard_breaker-li {
    display: block;
    width: 5% !important;
  }

  nav {
    width: 100%;
    margin-left: 8%;
  }
  /* .css-145eoda {
     width: 50vw; 
  } */
}

@media (max-width: 1040px) {
  .breaker-li {
    display: block;
    width: 10% !important;
  }

  .cust-breaker-li {
    display: block;
    width: 6% !important;
  }

  .dashboard_breaker-li {
    display: none;
    width: 0 !important;
  }
}

@media (max-width: 992px) {
  nav {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    justify-content: space-between;
    padding-top: 0px !important;
  }

  nav .logo {
    margin: 0px 0px 0px 20px;
  }

  nav .mainMenu {
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
    height: 100vh;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 10;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    transition: top 1s ease 0s;
    display: none;
  }

  .breaker-li {
    display: none;
  }
  .cust-breaker-li {
    display: none;
  }

  nav .mainMenu li {
    width: 100% !important;
    margin: 0px 5px !important;
    transition: 0.2s ease;
    margin: 5px 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  nav .mainMenu li Button,
  nav .mainMenu .signup_link .signup-btn,
  nav .mainMenu .login_link .login-btn {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 18px;
    cursor: pointer;
  }

  nav .mainMenu .closeMenu {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  nav .openMenu {
    display: block;
  }

  /* nav .mainMenu li:hover {
    background: none;
    color: rgb(255, 123, 0);
  } */

  nav .mainMenu li:hover {
    background: rgb(92, 83, 83);
  }

  .icons i {
    display: inline-block;
    padding: 12px;
  }

  .signup-model {
    width: 9rem;
    background-color: #ffffff;
    top: 20px;
    left: 55%;
    right: auto;
    z-index: 100000;
  }

  .login-model {
    width: 9rem;
    background-color: #ffffff;
    top: 20px;
    left: 55%;
    right: auto;
    z-index: 100000;
  }
}
