.auction_title {
  font-size: 20px;
  line-height: 23.54px;
  font-weight: 600;
}

.my_auctions__bids,
.time-left {
  font-size: 18px;
  color: rgb(0, 0, 0);
  background-color: #ecf0ff;
  font-weight: 500;
}

.bids_time .time {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.48px;
  color: #6a7d9f;
}
.ratings {
  font-size: 16px;
  font-weight: 500;
  color: #6a7d9f;
}

.location_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.83px;
  color: #1a1b1e;
}

.filter_choosed {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.83px;
  color: #004c6e;
  background-color: #d6ecff;
  border-radius: 4px;
}

.bid_price {
  font-size: 16px;
  font-weight: 500;
  color: #2a9928;
  border-radius: 4px;
  background-color: #e6ffda;
}

@media (max-width: 1024px) {
  .auction_title {
    font-size: 18px;
  }
  .my_auctions__bids,
  .time-left {
    font-size: 16px;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
}

@media (max-width: 568px) {
  .my_auctions__bids,
  .time-left {
    font-size: 14px !important;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
}
