.my_auctions__container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 7rem 75px;
}

@media (max-width: 1024px) {
  .my_auctions__container {
    padding: 4rem 5rem 0;
  }
}

@media (max-width: 768px) {
  .my_auctions__container {
    padding: 4rem 4rem 0;
  }
}

@media (max-width: 568px) {
  .my_auctions__container {
    padding: 3rem 3rem 0;
  }
}

@media (max-width: 426px) {
  .my_auctions__container {
    padding: 2.5rem 1rem 0;
  }
}
