.seller_details__container {
  width: 100%;
  height: auto;
  margin-top: 75px;
  padding: 75px 7rem 75px 7rem;
  background-color: rgb(253, 251, 255);
}

.steps_headings {
  font-size: 25px;
}

.seller_details_steps__container {
  .seller_details_steps__container {
    width: 60%;
  }
}

.seller_profile__contents {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 2rem;
}

.business__profile_content {
  background-color: #ffffff;
  width: 50rem;
}

@media (max-width: 1400px) {
  .seller_details__container {
    padding: 75px 6.8rem 0px 6.8rem;
  }
  .business__profile_content {
    width: 50rem;
  }
  .seller_profile__contents {
    gap: 2.5rem;
  }
}

@media (max-width: 1200px) {
  .seller_details__container {
    padding: 75px 4.8rem 0px 4.8rem;
  }
  .business__profile_content {
    width: 41rem;
  }
  .seller_profile__contents {
    gap: 2rem;
  }
}

@media (max-width: 992px) {
  .seller_details__container {
    padding: 75px 4rem 0px 4rem;
  }

  .seller_details_steps__container {
    width: 40%;
  }

  .business__profile_content {
    width: 100%;
  }
  .seller_profile__contents {
    gap: 1.4rem;
  }
}

@media (max-width: 568px) {
  .seller_details__container {
    padding: 30px 0.8rem 0 0.8rem;
  }
}

@media (max-width: 435px) {
  .steps_headings {
    font-size: 20px;
  }
}
