#cust_sub_categories__container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 7rem 4rem;
}

@media (max-width: 1200px) {
  #cust_sub_categories__container {
    padding: 4rem 4.5rem 4rem 4.5rem;
  }
}

@media (max-width: 768px) {
  #cust_sub_categories__container {
    padding: 4rem 3rem 4rem 3rem;
  }
}

@media (max-width: 576px) {
  #cust_sub_categories__container {
    padding: 4rem 1rem !important;
  }
}
