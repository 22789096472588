.dashboard-box {
  width: 100%;
  height: auto;
  padding-top: 20px;
}

.dashboard_contents {
  width: 100%;
  height: auto;
  padding: 30px 6.5rem 4rem;
}

.dasboard-content {
  width: 100%;
}

@media (min-width: 1400px) {
  .dashboard_contents {
    width: 100%;
    height: auto;
    padding: 30px 7rem 4rem;
  }
}

@media (max-width: 1200px) {
  .dashboard_contents {
    padding: 30px 4.5rem 4rem;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .dashboard_contents {
    padding: 4rem 3rem;
  }
}

@media (max-width: 576px) {
  .dashboard_contents {
    padding: 4rem 1rem !important;
    overflow: scroll;
  }
  /* .dasboard-content {
    width: 25rem !important;
  } */
}
