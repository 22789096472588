@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.chat_box__container {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 0;
  padding-bottom: 0;
}

.chats_contects__container {
  position: relative;
  width: 140vh;
  height: 87.2vh;
  margin-top: 75px;
  background-color: #ffffff;
  padding: 2rem 2rem 0 5rem;
}

.chats_contacts__box {
  width: 90%;
  height: 100%;
  float: right;
}

.chats_contacts__box .contacts_box--container {
  width: 100%;
  height: 60vh;
  overflow: scroll;
}

.chatting_area_container {
  width: 100%;
  height: 100vh;
  background-color: #eeedf1;
  position: relative;
}
