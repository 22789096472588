.products__services___container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 6.5rem 75px;
}

.products__services___buttons .seller_links__underline_container {
  position: relative;
  width: 20.6rem;
  height: 5px;
  background-color: #e3e2e6;
  border-radius: 5px;
}

.products__services___buttons .seller_links__underline_container .underline {
  position: absolute;
  width: 9rem;
  padding: 2px;
  background-color: #fa9b00;
  border-radius: 7px 7px 0px 0px;
  transition: transform 0.3s ease;
}

.seller_links__underline_container .move-left {
  transform: translateX(0);
}

.seller_links__underline_container .move-right {
  transform: translateX(123%);
}

@media (min-width: 1024px) {
  .seller_links__underline_container .move-right {
    transform: translateX(123%) !important;
  }
}

@media (max-width: 1024px) {
  .products__services___container {
    padding: 4rem 3.5rem 75px;
  }
  .seller_links__underline_container .move-right {
    transform: translateX(123%) !important;
  }
}

@media (max-width: 768px) {
  .products__services___container {
    padding: 4rem 3rem 75px;
  }
}

@media (max-width: 576px) {
  .products__services___container {
    padding: 4rem 3.5rem 75px;
  }

  .products__services___buttons .seller_links__underline_container {
    width: 16.6rem;
  }

  .products__services___buttons .seller_links__underline_container .underline {
    width: 7rem;
  }
  .seller_links__underline_container .move-right {
    transform: translateX(129%) !important;
  }
}

@media (max-width: 456px) {
  .products__services___container {
    padding: 4rem 1rem 75px;
  }
  .products__services___buttons .seller_links__underline_container {
    width: 17rem !important;
  }
  .products__services___buttons .seller_links__underline_container .underline {
    width: 40%;
  }
  .seller_links__underline_container .move-right {
    transform: translateX(142%) !important;
  }
}

@media (max-width: 320px) {
  .products__services___buttons .seller_links__underline_container {
    width: 100% !important;
  }
}
