@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.info-bids {
  font-size: 28px;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
}

.bidder-content {
  background-color: rgba(255, 255, 255, 1);
  width: auto;
}

.ratings,
.bidder-add-date {
  color: rgba(106, 125, 159, 1);
}

.bid-title {
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
}

.certify-check,
.certify-check-icon {
  color: rgba(23, 43, 77, 1);
  background-color: rgb(240, 241, 244);
  font-size: 18px;
  line-height: 18.83px;
}

.ratings-stars-icons {
  color: rgba(250, 155, 0, 1);
}

.bed-bug-termination,
.pest-control {
  background-color: rgba(214, 236, 255, 1);
  color: rgba(0, 76, 110, 1);
  padding: 6px 8px 6px 8px;
  font-size: 18px;
  line-height: 18.83px;
  font-weight: 600;
}

.bids-price-button {
  background-color: rgba(230, 255, 218, 1);
  color: rgba(42, 153, 40, 1);
  font-size: 18px;
  padding: 6px 10px 6px 10px;
  text-transform: none;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.pagination li {
  list-style: none;
  margin: 0 5px;
  width: 31px;
  height: 32px;
  text-align: center;
  padding: 0px, 12px, 0px, 12px;
}

.pagination li a {
  text-decoration: none;
  color: rgba(0, 27, 61, 1);
  font-size: 16px;
  line-height: 20px;
}

.pagination li.active a,
.pagination li.active {
  background-color: rgba(208, 223, 255, 1);
  font-weight: bold;
  border-radius: 4px;
}
