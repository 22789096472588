.description__heading_and_content .description_heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 49px;
  text-align: left;
  color: #000000;
}

.description__heading_and_content .description_content {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.41px;
  text-align: left;
  color: #6a7d9f;
}

.features__heading_and_content .features__heading,
.specifications__heading_and_content .specifications__heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 29.4px;
  text-align: left;
  color: #000000;
}

.features__heading_and_content .features__list {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.41px;
  text-align: left;
  color: #000000;
}

.features__heading_and_content .features__list .features_list_para,
.specifications__heading_and_content .specifications__list {
  color: #6a7d9f;
  font-weight: 500;
}

.recommended__products .recommeded__see_more {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.recommended__products .rcommended_heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 49px;
  text-align: left;
  color: #000000;
}

.product_card__content .product__img {
  width: 100%;
  height: 17rem;
  border-radius: 3px;
  object-fit: cover;
  object-position: bottom;
}

.product_info_section .product__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.26px;
  color: #000;
  text-transform: none !important;
}

.recommended_product_card__container .react-multiple-carousel__arrow--right {
  right: 0 !important;
  top: 46% !important;
  background-color: #6a7d9f !important;
  border: 3px solid #fdfbff !important;
}
.recommended_product_card__container .react-multiple-carousel__arrow--left {
  left: 0 !important;
  top: 46% !important;
  background-color: #6a7d9f !important;
  border: 3px solid #fdfbff !important;
}

@media (max-width: 687px) {
  .product_card__content .product__img {
    width: 100%;
    height: 17rem;
    border-radius: 3px;
    object-fit: cover;
    object-position: bottom;
  }

  .description__heading_and_content .description_content,
  .features__heading_and_content .features__list,
  .specifications__heading_and_content .specifications__list {
    font-size: 14px;
    line-height: 15.41px;
  }

  .description__heading_and_content .description_heading,
  .recommended__products .rcommended_heading {
    font-size: 30px;
    line-height: 39px;
  }
  .see_more__btn {
    text-align: end;
    width: auto;
  }
}

@media (max-width: 490px) {
  .recommended__products .recommeded__see_more {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }
  .see_more__btn {
    text-align: center;
    width: 100%;
  }
}
