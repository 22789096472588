.my_order_page__container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 7rem 4rem;
}

.order_page__profile__status .order__page_content .order_title {
  font-size: 32px;
  font-weight: 600;
  line-height: 38.18px;
  color: #000000;
}

.order_page__profile__status .order__page_content .product__image {
  width: 10rem;
  height: 10rem;
  border-radius: 5px;
}
.cust_product__image_container {
  width: 11rem;
  height: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cust_product__image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.order_page__profile__status .order__page_content .order_status {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.83px;
}

.order_page__profile__status .order__page_content .order_id,
.order_page__profile__status .order__page_content .order_quantity {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.54px;
  color: #6a7d9f;
}

.order_page__profile__status .order_profile__content .order_name {
  font-size: 24px;
  font-weight: 400;
  line-height: 27.84px;
  color: #000000;
}

.order_service_title__area .order_service__title,
.service_heading__text,
.services_text {
  font-size: 20px;
  font-weight: 600;
  line-height: 23.86px;
  color: #2f3033;
}

.order_service_title__area .order_service_area {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.83px;
  color: #2f3033;
}

.order_service__total_price {
  font-size: 20px;
  font-weight: 400;
  line-height: 23.2px;
  color: #275ea7;
}

.order_service__payment_info {
  background-color: #e9f2ff;
  border-radius: 3px;
  width: 85%;
}

.order_service__payment_info .order_service_payment_info__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.83px;
  color: #1365af;
}

@media (max-width: 1200px) {
  .my_order_page__container {
    padding: 4rem 4.5rem 4rem 4.5rem;
  }
}

@media (max-width: 992px) {
  .cust_product__image_container {
    width: 9rem;
    height: 9rem;
  }
}

@media (max-width: 768px) {
  .my_order_page__container {
    padding: 4rem 3rem 4rem 3rem;
  }

  .cust_product__image_container {
    width: 7rem;
    height: 7rem;
  }

  .order_page__profile__status .order__page_content .order_title {
    font-size: 22px;
    font-weight: 600;
    line-height: 36.18px;
    color: #000000;
  }
  .order_page__profile__status .order__page_content .order_status {
    font-size: 13px;
    font-weight: 500;
    line-height: 16.83px;
  }

  .order_page__profile__status .order__page_content .order_id,
  .order_page__profile__status .order__page_content .order_quantity {
    font-size: 16px;
    font-weight: 500;
    line-height: 23.54px;
    color: #6a7d9f;
  }
  .order_service_title__area .order_service__title,
  .service_heading__text,
  .services_text {
    font-size: 16px;
    font-weight: 600;
    line-height: 23.86px;
    color: #2f3033;
  }
  .order_page__profile__status .order_profile__content .order_name {
    font-size: 20px;
    font-weight: 400;
    line-height: 23.84px;
    color: #000000;
  }
}

@media (max-width: 576px) {
  .my_order_page__container {
    padding: 4rem 1.5rem !important;
  }

  .cust_product__image_container {
    width: 100%;
    height: 12rem;
    border-radius: 10px;
  }

  .order_page__profile__status .order__page_content .order_title {
    font-size: 20px;
    font-weight: 600;
    line-height: 38.18px;
    color: #000000;
  }

  .order_service__payment_info {
    background-color: #e9f2ff;
    border-radius: 3px;
    width: 100%;
  }
  .order_service__payment_info .order_service_payment_info__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.83px;
    color: #1365af;
  }

  .order_page__profile__status .order__page_content .order_status {
    font-size: 12px;
  }
}

@media (max-width: 420px) {
  .order_page__profile__status .order__page_content .product__image {
    width: 100%;
    height: 11rem;
    object-fit: cover;
    object-position: top;
  }
}
