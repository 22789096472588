.socila-links {
  width: 100%;
  height: auto;
}

.social_links_heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.63px;
  letter-spacing: 0%;
  color: #000;
}
.links-label {
  width: 12rem;
  color: rgba(106, 125, 159, 1);
  font-size: 16px;
  line-height: 18.83px;
  font-weight: 500;
}

.social_links_box .links {
  width: 25rem;
  border: 2px solid rgb(221, 224, 229);
  border-radius: 3px;
}

@media (max-width: 756px) {
  .social_links_heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 24.09px;
    letter-spacing: 0%;
    color: #000;
  }
}
@media (max-width: 576px) {
  .social_links_box .links {
    width: 100%;
  }
}
