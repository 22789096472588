@font-face {
  font-family: 'Gilroy-regular';
  src: url(../../../../public/fonts/Gilroy-Regular.ttf) format('truetype');
}

.gilroy {
  font-family: 'Gilroy-regular' !important;
  text-transform: capitalize !important;
}

.verification-container{
  width: 45rem;
  height: auto;
}

/* label{
  font-size: 15px;
} */

.email-verificationn-from-section{
  padding: 35px 35px 35px 0px;
}

.otp-box{
  border-color:rgba(194, 199, 207, 1);
}