.dashboard__line_chart .css-zlaec3-MuiAreaElement-root{
  fill: #d6fffd !important;
}

.dashboard__line_chart .css-16pjmom-MuiLineElement-root {
    stroke: #d6fffd !important
}

.dashboard__line_chart .css-1wqktu4-MuiMarkElement-root{
    stroke-width: 1 !important;
}