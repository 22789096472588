@font-face {
  font-family: "Gilroy-regular";
  src: url(.././../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.main-box {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 3px;
}

.icon {
  width: 48px !important;
  height: 48px !important;
  color: #fa9b00;
}

.mat-icon {
  color: #fa9b00;
}

.count {
  color: #1365af;
  text-align: center;
  font-family: "Gilroy-regular" !important;
  font-size: 44px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.title {
  color: #42474e;
  text-align: center;
  font-family: "Gilroy-regular" !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
