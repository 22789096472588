@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.login-page {
  width: 100%;
  height: auto;
  margin-bottom: -70px;
  padding-bottom: 0;
}
.form {
  width: 100%;
}

.back-arrow {
  color: rgba(0, 0, 0, 1);
}

h1 {
  font-size: 35px;
  color: rgba(0, 0, 0, 1);
}

.welcome {
  color: rgba(19, 101, 175, 1);
}

.login-img {
  width: 31rem;
  height: 50rem;
  position: relative;
  object-fit: cover;

  /* background-size: 100rem 100%;
  background-position: 74.5%;
  background-repeat: no-repeat; */
}

.login_btn {
  width: auto;
}

@media (max-width: 568px) {
  .login_btn {
    width: 100% !important;
  }
}

@media (max-width: 1200px) {
  .login-img {
    width: 24rem;
  }
  .login_heading {
    font-size: 30px;
  }
}

@media (max-width: 992px) {
  .login-img {
    width: 22rem;
  }
}
@media (max-width: 768px) {
  .login-img {
    width: 18rem !important;
  }
}

@media (max-width: 664px) {
  .login-page {
    height: 50rem;
  }
}
