.my_products_categories_results__container {
  width: 100%;
}

.my_products_categories_results__container .categories_heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.26px;
  color: #343538;
}

.product_card__content {
  background-color: #ffffff;
}

.product_card__content .product__img {
  width: 100%;
  height: 17rem;
  border-radius: 3px;
  object-fit: cover;
  object-position: top;
}

.product_info_section .prduct__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.26px;
  color: #000;
  text-transform: none !important;
}

.product_info_section .product__quantity {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.41px;

  color: #6a7d9f;
}

.ratings .ratings__viewers {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.41px;
  color: #000;
}

.product__price_content .orignal__prod_price {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.41px;
  color: #6a7d9f;
}

.product__price_content .disconted__prod_price {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.26px;
  color: #000;
}

.delivery_icon {
  width: 22px;
  height: 21px;
}

.delivery .delivery_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.41px;
  color: #2bbefd;
}

@media (max-width: 1024px) {
  .my_products_categories_results__container {
    width: 100%;
  }

  .product__price_content .orignal__prod_price {
    font-size: 14px;
    line-height: 19.41px;
  }

  .product__price_content .disconted__prod_price {
    font-size: 18px;

    line-height: 24.26px;
  }
}
