@font-face {
  font-family: 'Gilroy-regular';
  src: url(../../../../public/fonts/Gilroy-Regular.ttf) format('truetype');
}

.gilroy {
  font-family: 'Gilroy-regular' !important;
  text-transform: capitalize !important;
}

.auction-details-box{
  width: 100%;
  height: auto;
  margin-top: 75px;
  padding: 4rem 9rem 4rem 9rem;
}



