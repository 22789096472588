@font-face {
  font-family: "Gilroy-regular";
  src: url(../.././../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy-regular {
  font-family: "Gilroy-regular" !important;
}

.popular_carousel .react-multiple-carousel__arrow--left {
  left: 0 !important;
  top: 24% !important;
  position: absolute !important;
  background-color: #6a7d9f !important;
  border: 3px solid #fdfbff !important;
  /* margin-top: -4rem !important; */
}

.popular_carousel .react-multiple-carousel__arrow--right {
  right: 0 !important;
  top: 24% !important;
  background-color: #6a7d9f !important;
  position: absolute !important;
  border: 3px solid #fdfbff !important;
  /* margin-top: -4rem !important; */
}

/* .react-multiple-carousel__arrow--left,
.react-multiple-carousel__arrow--right {
  background-color: #6a7d9f !important;
  position: absolute !important;
  border: 3px solid #fdfbff !important;
  margin-top: -2rem !important;
  
} */

.css-d1jntq-MuiGrid-root {
  margin-top: 40px;
}
