.service_info_section {
  width: 80%;
  position: relative;
}

.label-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.41px;
  text-align: left;
  color: #1a1b1e;
}

@media (max-width: 992px) {
  .service_info_section {
    width: 90%;
  }
}
@media (max-width: 768px) {
  .service_info_section {
    width: 100%;
  }
}
