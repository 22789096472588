@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.start_bidding__container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 7rem 4rem;
}

.minicategories--container {
  width: 20rem;
  height: auto;
}

.bid_heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 47.72px;
  color: #000;
}

.bidding_img_container {
  width: 30%;
}

.request_details__img {
  width: 295px;
  height: 1080px;
}

.multi-step-buttons-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
}

.multi-step-buttons-container button {
  margin: 0 10px;
}

.service_category_container .css-z7uhs0-MuiStepConnector-line {
  display: block;
  border-color: #bdbdbd;
  border-top-style: solid;
  border-top-width: 5px;
  border-radius: 5px;
  z-index: 0;
}
.service_category_container .css-zpcwqm-MuiStepConnector-root {
  top: 12px;
  left: calc(-91% + 20px);
  right: calc(9% + 20px);
  z-index: 0;
}
.service_category_container
  .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: -52px;
}

.service_category_container
  .css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  padding-right: 0;
  z-index: 100;
  border: 5px solid #fff;
  border-radius: 50%;
  background: #fff;
}

/* .service_category_container .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: #b6b6b6;
} */

@media (max-width: 1200px) {
  .start_bidding__container {
    padding: 4rem 4.5rem 4rem 4.5rem;
  }
}
@media (max-width: 992px) {
  .bidding_img_container {
    width: 35%;
  }
}

@media (max-width: 768px) {
  .start_bidding__container {
    padding: 4rem 3rem 4rem 3rem;
  }
}

@media (max-width: 576px) {
  .start_bidding__container {
    padding: 4rem 1.5rem !important;
  }
  .bid_heading {
    font-size: 30px;
  }
}

@media (max-width: 1024px) {
  .minicategories--container .request_details__img {
    width: 280px;
  }
}
