@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize;
}

.share-button-container {
  right: 9rem;
  top: 18rem;
}

.profile-pic__container {
  width: 100%;
  height: 20rem;
  margin: 0;
  border-radius: 10px;
  border: 1px solid black;
  overflow: hidden;
}

.profile-pic__container .banner_account_img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
  object-position: bottom;
}

.business_name_lable {
  font-weight: 500;
  font-size: 16px;
  line-height: 18.83px;
  letter-spacing: 0%;
  color: rgba(106, 125, 159, 1);
}

.business_name_text {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.63px;
  letter-spacing: 0%;
  color: #000;
}

.edit_pen_size {
  width: 20px;
}

@media (max-width: 756px) {
  .business_name_lable {
    font-weight: 500;
    font-size: 14px;
    line-height: 18.12px;
    letter-spacing: 0%;
    color: rgba(106, 125, 159, 1);
  }
  .business_name_text {
    font-weight: 600;
    font-size: 18px;
    line-height: 24.09px;
    letter-spacing: 0%;
    color: #000;
  }
}
@media (max-width: 576px) {
  .business_name_lable {
    font-weight: 500;
    font-size: 12px;
    line-height: 14.12px;
    letter-spacing: 0%;
    color: rgba(106, 125, 159, 1);
  }
  .business_name_text {
    font-weight: 600;
    font-size: 16px;
    line-height: 19.09px;
    letter-spacing: 0%;
    color: #000;
  }
}

@media (max-width: 420px) {
}
