.my_orders_container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 7rem 0;
}

@media (max-width: 1200px) {
  .my_orders_container {
    padding: 4rem 4.5rem 4rem 4.5rem;
  }
}

@media (max-width: 768px) {
  .my_orders_container {
    padding: 4rem 3rem 4rem 3rem;
  }
}

@media (max-width: 576px) {
  .my_orders_container {
    padding: 4rem 1.5rem !important;
  }
}
