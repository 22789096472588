.add_products_container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 7rem 75px;
}

.upload_products_img .file-upload-container {
  width: auto;
  /* border: 1px dashed #ccc; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.file-upload-container .file-drop-zone {
  border: 2px dashed #ccc;
  border-radius: 8px;
  width: auto;
  height: auto;
  position: relative;
}

.upload_para {
  font-size: 14px;
  line-height: 16.24px;
  font-weight: 400;
  text-align: start;
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

@media (max-width: 768px) {
  .add_products_container {
    padding: 4rem 4rem 75px;
  }
}

@media (max-width: 568px) {
  .add_products_container {
    padding: 4rem 1rem 75px !important;
  }
}
