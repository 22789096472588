.my_auctions_details__container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 7rem 75px;
}

.auction_details_button__bids_button .auction_details__button {
  border-radius: 25px 0 0 25px;
  width: 25rem;
  height: 2.5rem;
  text-transform: none;
  font-size: 16px;
}

.auction_details_button__bids_button .aution_bids_button {
  border-radius: 0 25px 25px 0;
  width: 25rem;
  height: 2.5rem;
  text-transform: none;
  font-size: 16px;
}
