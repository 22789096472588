.customer_notifications__container {
  width: 100%;
  height: 30rem;
  position: relative;
  margin-top: 75px;
  padding: 4rem 7rem 0;
}

.notification_by {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.83px;
  color: #1a1b1e;
}

.notification_time {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.83px;
  color: #6a7d9f;
}
