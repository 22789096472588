.reviews_container .review_heading {
  font-size: 32px;
  font-weight: 600;
  line-height: 38.18px;
  color: "#000000";
}

.reviews_container .business_reviews__progress_bar {
  width: 100%;
  height: auto;
  border: 1.5px solid rgba(194, 199, 207, 1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  justify-items: center;
}
