/* .profilePicContainer{
   
} */

.bidder-profile {
  width: 100%;
  height: auto;
}

/* .react-multi-carousel-list {
    position: absolute !important;
  } */

.bidder_profile__carousel .react-multiple-carousel__arrow--left {
  left: 0px !important;
  top: 39% !important;
}

.bidder_profile__carousel .react-multiple-carousel__arrow--right {
  right: 0px !important;
  top: 39% !important;
}
