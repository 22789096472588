.auction_details__container {
  width: 67%;
  padding: 0 30px;
}

.auction_details__container .details_title {
  font-size: 20px;
  font-weight: 600;
  line-height: 23.86px;
  color: #1365af;
}

.auction_details__container .details_content {
  font-size: 18px;
  font-weight: 500;
  line-height: 18.83px;
  color: #2f3033;
}
