.service--categories__minicateg {
  width: auto;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.63px;
  color: rgba(52, 53, 56, 1);
}

@media (max-width: 1040px) {
  .service--categories__minicateg {
    width: auto;
  }
}
