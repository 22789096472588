.auction_bids__container {
  width: 100%;
}

.bids_info__content {
  font-size: 28px;
  font-weight: 600;
  line-height: 33.4px;
  color: #1a1b1e;
}

.bids_info__label {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.83px;
  color: #6a7d9f;
}
