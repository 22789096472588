@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.menuItem-disabled {
  color: rgba(171, 171, 175, 1);
}

/* .verification-container {
  width: 45rem;
  height: auto;
} */

.registration-form-section {
  padding: 35px 33px 35px 33px;
}

.css-ahj2mt-MuiTypography-root {
  font-size: 1rem !important;
}

.verification-type-heading .subheading {
  color: rgba(106, 125, 159, 1);
}

@media (max-width: 425px) {
  .css-ahj2mt-MuiTypography-root {
    font-size: 0.8rem !important;
  }
}
