@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.recent_order__carousel .carousel {
  padding: 20px;
  background: white;
  border-radius: 10px;
  margin: 15px;
}

.recent_order__carousel .react-multi-carousel-dot button {
  border: 1px solid grey;
}

.recent_order__carousel .carousel-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 23.54px;
  text-align: left;
}

.recent_order__carousel .carousel-order-id {
  color: rgba(106, 125, 159, 1);
  font-size: 16px;
  font-weight: 500;
  line-height: 18.83px;
  text-align: left;
}

.recent_order__carousel .carousel-order-date .calendar-icon,
.recent_order__carousel .carousel-order-location .map-icon,
.recent_order__carousel .carousel-order-information .info-icon {
  width: 18.75px;
  height: 18.75px;
  color: #6a7d9f;
}

.recent_order__carousel .carousel-text {
  color: #626f86;
  font-size: 16px;
}
.recent_order__carousel .carousel-order-price {
  color: rgba(42, 153, 40, 1);
  font-weight: 600;
  font-size: 20px;
}
.recent_order__carousel .carousel-order-status {
  padding: 4px 10px;
  color: rgba(137, 132, 0, 1);
  border-radius: 40px;
  background-color: rgba(242, 239, 207, 1);
  font-size: 16px;
}

@media (max-width: 1200px) {
  .recent_order__carousel .carousel-name {
    font-size: 18px;
    font-weight: 600;
    line-height: 20.54px;
    text-align: left;
    color: "#000000";
  }

  .recent_order__carousel .carousel-order-id {
    color: rgba(106, 125, 159, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 14.83px;
    text-align: left;
  }

  .recent_order__carousel .carousel-order-date .calendar-icon,
  .recent_order__carousel .carousel-order-location .map-icon,
  .recent_order__carousel .carousel-order-information .info-icon {
    width: 16.75px;
    height: 16.75px;
    color: #6a7d9f;
  }

  .recent_order__carousel .carousel-text {
    font-size: 14px;
  }

  .recent_order__carousel .carousel-order-status {
    padding: 6px 15px;
    font-size: 14px;
  }

  .recent_order__carousel .carousel-order-price {
    color: rgba(42, 153, 40, 1);
    font-weight: 600;
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .recent_order__carousel .carousel-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 17.54px;
    text-align: left;
    color: "#000000";
  }

  .recent_order__carousel .carousel-order-id {
    color: rgba(106, 125, 159, 1);
    font-size: 12px;
    font-weight: 500;
    line-height: 12.83px;
    text-align: left;
  }

  .recent_order__carousel .carousel-order-date .calendar-icon,
  .recent_order__carousel .carousel-order-location .map-icon,
  .recent_order__carousel .carousel-order-information .info-icon {
    width: 14.75px;
    height: 14.75px;
    color: #6a7d9f;
  }

  .recent_order__carousel .carousel-text {
    font-size: 12px;
  }

  .recent_order__carousel .carousel-order-status {
    padding: 6px 12px;
    font-size: 12px;
  }

  .recent_order__carousel .carousel-order-price {
    color: rgba(42, 153, 40, 1);
    font-weight: 600;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .recent_order__carousel .react-multi-carousel-dot button {
    width: 10px;
    height: 10px;
    border: 1px solid grey;
  }

  .recent_order__carousel .carousel-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 17.54px;
    text-align: left;
    color: "#000000";
  }

  .recent_order__carousel .carousel-order-id {
    color: rgba(106, 125, 159, 1);
    font-size: 12px;
    font-weight: 500;
    line-height: 12.83px;
    text-align: left;
  }

  .recent_order__carousel .carousel-order-date .calendar-icon,
  .recent_order__carousel .carousel-order-location .map-icon,
  .recent_order__carousel .carousel-order-information .info-icon {
    width: 14.75px;
    height: 14.75px;
    color: #6a7d9f;
  }

  .recent_order__carousel .carousel-text {
    font-size: 12px;
  }

  .recent_order__carousel .carousel-order-status {
    padding: 6px 13px;
    font-size: 12px;
  }

  .recent_order__carousel .carousel-order-price {
    color: rgba(42, 153, 40, 1);
    font-weight: 600;
    font-size: 16px;
  }
}

@media (min-width: 578px) and (max-width: 678px) {
  .recent_order__carousel .carousel {
    padding: 20px;
  }

  .recent_order__carousel .carousel-order-status {
    padding: 6px 13px;
    font-size: 11px;
  }

  .recent_order__carousel .carousel-order-price {
    color: rgba(42, 153, 40, 1);
    font-weight: 600;
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .recent_order__carousel .react-multi-carousel-dot button {
    width: 9px;
    height: 9px;
    border: 1px solid grey;
  }

  .recent_order__carousel .carousel {
    padding: 20px;
    background: white;
    border-radius: 10px;
    margin: 0px;
  }

  .recent_order__carousel .carousel-order-price {
    color: rgba(42, 153, 40, 1);
    font-weight: 600;
    font-size: 13px;
  }

  .recent_order__carousel .carousel-order-status {
    padding: 5px 5px;
    font-size: 12.8px;
  }
}
