.chats_contacts__box__container {
  width: 100%;
  height: auto;
}

.chats_contacts__box__container .contact_box__active {
  background-color: #eeeeee;
  gap: 15px;
}

.chats_contacts__box__container .contact_box {
  gap: 15px;
}

.chats_contact__name__isactive .chat_contact__name {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.48px;
  text-align: left;
  color: #000;
}

.chats_contact__name__isactive .chats_last_seen__time {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #535353;
}

.chats_last_seen__chats {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.24px;
  text-align: left;
  color: #77777a;
}
