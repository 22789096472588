@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

div .auction-all-details {
  width: 78%;
  margin: 1rem auto;
}

.details-titles {
  color: rgba(19, 101, 175, 1);
}

.all-details {
  color: rgba(47, 48, 51, 1);
  font-weight: 400;
}

.popup_content {
  width: 47.5rem;
  height: 23.625rem;
}
