@font-face {
  font-family: "Gilroy-regular";
  src: url(.././../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.recent-order-box {
  width: 100%;
  /* padding: 2rem 7rem 2rem 7rem; */
  margin-bottom: 1rem;
}

.recent_orders__heading {
  font-size: 45px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
}

@media (max-width: 768px) {
  .recent_orders__heading {
    font-size: 40px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
  }
}

@media (max-width: 576px) {
  .recent_orders__heading {
    font-size: 28px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
  }
}
