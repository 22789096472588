.ongoing-button--biddedon-button .ongoing_button__styles,
.details-button--bids-button .details-button {
  border-radius: 25px 0 0 25px;
  width: 25rem;
  height: 2.5rem;
  text-transform: none;
  font-size: 16px;
}

.ongoing-button--biddedon-button .bidden_button__style,
.details-button--bids-button .bids-button {
  border-radius: 0 25px 25px 0;
  width: 25rem;
  height: 2.5rem;
  text-transform: none;
  font-size: 16px;
}

@media (max-width: 420px) {
  .ongoing-button--biddedon-button .ongoing_button__styles,
  .details-button--bids-button .details-button {
    border-radius: 25px;
    width: auto;
  }

  .ongoing-button--biddedon-button .bidden_button__style,
  .details-button--bids-button .bids-button {
    width: auto;
    border-radius: 25px;
  }
}
