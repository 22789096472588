@font-face {
  font-family: "Gilroy-regular";
  src: url(.././../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.dashboard_carousel_img {
  width: 100%;
  height: 64vh;
  position: relative;
  overflow: hidden;
}

.dashboard__carousel .carousel-inner {
  margin-top: 0px;
}

.dashboard__carousel .carousel-control-prev-icon,
.dashboard__carousel .carousel-control-next-icon {
  display: none !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-color: #1a1b1e !important;
}

.dashboard__carousel .carousel-indicators [data-bs-target] {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  /* box-shadow: 2px 4px 0px #ddd !important;    */
}

.dashboard__carousel .carousel-indicators .active {
  background-color: #1a1b1e !important;
}

.dashboard__carousel .carousel-caption {
  width: 40%;
  height: auto !important;
  left: 7%;
  right: 53%;
  top: 15%;
  padding: 0 !important;
  text-align: left !important;
}

.dashboard__carousel .carousel-caption h3 {
  font-family: "Gilroy-regular" !important;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 992px) {
  .dashboard_carousel_img {
    height: 42vh;
  }
}

@media (max-width: 768px) {
  .dashboard__carousel .carousel-caption {
    width: 80% !important;
    left: 10% !important;
    right: 10% !important;
    padding: 0 !important;
    text-align: center !important;
  }

  .dashboard__carousel .carousel-caption h3 {
    font-size: 25px !important;
  }
  .dashboard_carousel_img {
    height: 38vh;
  }
}

@media (max-width: 576px) {
  .dashboard_carousel_img {
    height: 32vh;
  }
}
