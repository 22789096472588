@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.bids-status {
  font-size: 12px;
  border-radius: 3px;
  color: rgba(42, 153, 40, 1);
  background-color: rgba(217, 244, 217, 1);
  padding: 1px 5px;
}

.content-title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
}

.details {
  font-size: 16px;
  color: rgba(106, 125, 159, 1);
  font-weight: 600;
}

.filter-point {
  color: rgba(0, 30, 47, 1);
  background-color: rgba(214, 236, 255, 1);
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
  padding: 2px 15px;
}

.last-bid-amount {
  color: rgba(250, 155, 0, 1);
  font-size: 20px;
}

.sub-content {
  font-size: 14px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.pagination li {
  list-style: none;
  margin: 0 5px;
  width: 31px;
  height: 32px;
  text-align: center;
  padding: 0px, 12px, 0px, 12px;
}

.pagination li a {
  text-decoration: none;
  color: rgba(0, 27, 61, 1);
  font-size: 16px;
  line-height: 20px;
}

.pagination li.active a,
.pagination li.active {
  background-color: rgba(208, 223, 255, 1);
  font-weight: bold;
  border-radius: 4px;
}

.bids,
.hours-left {
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-weight: 500;
}

.avatars_container .css-17o22dy-MuiAvatar-root {
  width: 20px;
  height: 20px;
}

@media (max-width: 1024px) {
  .content-title {
    font-size: 18px;
  }

  .details {
    font-size: 14px;
    color: rgba(106, 125, 159, 1);
    font-weight: 600;
  }

  .filter-point {
    font-size: 14px;
    margin-left: 10px;
  }

  .last-bid-amount {
    color: rgba(250, 155, 0, 1);
    font-size: 18px;
  }
  .sub-content {
    font-size: 12px;
  }
  .bids,
  .hours-left {
    font-size: 15px;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
}

@media (max-width: 992px) {
  .details {
    font-size: 13px;
    color: rgba(106, 125, 159, 1);
    font-weight: 600;
  }

  .last-bid-amount {
    color: rgba(250, 155, 0, 1);
    font-size: 17px;
  }
  .sub-content {
    font-size: 11px;
  }
}

@media (max-width: 876px) {
  .content-title {
    font-size: 16px;
  }
  .bids-status {
    font-size: 10px;
  }
  .details {
    font-size: 13px;
  }
  .last-bid-amount {
    color: rgba(250, 155, 0, 1);
    font-size: 17px;
  }
  .bids,
  .hours-left {
    font-size: 13px;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
}

@media (max-width: 767px) {
  .details {
    font-size: 16px;
  }
  .last-bid-amount {
    color: rgba(250, 155, 0, 1);
    font-size: 20px;
  }
  .sub-content {
    font-size: 14px;
  }
  .bids,
  .hours-left {
    font-size: 12px;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
}

@media (max-width: 576px) {
  .details {
    font-size: 14px;
  }
  .filter-point {
    margin-left: 0 !important;
  }
}

@media (max-width: 350px) {
  .avatars_container .css-17o22dy-MuiAvatar-root {
    width: 16px;
    height: 16px;
  }
}
