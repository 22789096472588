.home_page_container {
  width: 100%;
  margin-top: 73px;
}

#sliders_container {
  width: 100%;
  height: auto;
  padding: 0 7rem;
}

.search__bar_container {
  width: 70%;
}

.search_bar {
  width: 60%;
  border-radius: 2px;
}

.search__bar_container .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0;
}

.search-category .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0;
}

.search-bar__input {
  border: none;
}

.search-bar__input:hover {
  border: none;
}

.search-bar__input:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 1400px) {
  #sliders_container {
    padding: 0 6.8rem;
  }
}

@media (max-width: 1200px) {
  #sliders_container {
    padding: 0 4.8rem;
  }
}

@media (max-width: 992px) {
  #sliders_container {
    padding: 0 3.5rem !important;
  }
}

@media (max-width: 568px) {
  #sliders_container {
    padding: 0 1rem !important;
  }
  #searchForService {
    height: 30px;
  }
  #searchForService::placeholder {
    font-size: 12px; /* Change this to your desired font size */
  }
}
