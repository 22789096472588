.bidder_profile__business_details__container .business_heading {
  font-size: 32px;
  font-weight: 600;
  line-height: 38.18px;
  color: #000000;
}

.bidder_profile__business_details__container .business_address {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.54px;
  color: #42474e;
}

.business_details_ratings__reviews .business_details__ratings__text {
  font-size: 28px;
  font-weight: 600;
  line-height: 33.4px;
  color: #000000;
}

.business_details_ratings__reviews .business_details__reviews__text {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.54px;
  color: #6a7d9f;
}
