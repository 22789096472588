.categories_buttons__container {
  width: 100%;
}

.categories_buttons__container .categories_heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.26px;
  color: #343538;
}
