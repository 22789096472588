.cust_order__details {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: flex-start;
}

.cust_order_img__container {
  width: 20%;
}
.cust_order_details_container {
  width: 80%;
}

.cust_order_img {
  width: 100%;
  height: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cust_order_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.order_title {
  font-size: 20px;
  line-height: 23.54px;
  font-weight: 600;
  color: #000000;
}

.order_area__pr_sq_ft {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.19px;
  color: #6a7d9f;
}

.details {
  font-size: 16px;
  color: rgba(106, 125, 159, 1);
  font-weight: 600;
}

.details-text {
  font-size: 15px;
}

/* .my_orders__images {
  width: 10rem;
  height: 10rem;
} */

.order-details-info--container {
  width: 100%;
}

/* .order_details_status {
  width: 30%;
} */

.my_orders__bids,
.time-left {
  font-size: 18px;
  color: rgb(0, 0, 0);
  background-color: #ecf0ff;
  font-weight: 500;
}

/* .orders_infos__text {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.19px;
  color: #6a7d9f;
} */

.order_infos__order_status {
  width: 30%;
}

.more-option--box {
  width: auto;
  right: 12rem;
  margin-bottom: 3rem;
  background-color: #ffffff;
}

.more-option {
  width: auto;
  height: auto;
}

.more-option .button-box:hover {
  background-color: #f7f7f7;
}

.more-option .button-box .view--details,
.more-option .button-box .delete--order {
  font-size: 16px;
  color: #000000;
}

.order_details_container {
  /* d-flex flex-column flex-md-row justify-content-start */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5rem;
}

.order_details_status {
  width: 13rem;
}

.seller_order_description {
  font-size: 16px;
  color: rgba(106, 125, 159, 1);
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@media (max-width: 1200px) {
  .more-option--box {
    width: auto;
    right: 9.5rem;
    margin-bottom: 3rem;
    background-color: #ffffff;
  }
  .order_details_container {
    gap: 3rem;
  }
}

@media (max-width: 1024px) {
  .order_title {
    font-size: 18px;
  }

  .order_area__pr_sq_ft {
    font-size: 16px;
  }

  .details {
    font-size: 13.5px;
    color: rgba(106, 125, 159, 1);
    font-weight: 600;
  }
  .details-text {
    font-size: 12.5px;
  }

  /* .my_orders__images {
    width: 8.5rem;
    height: 8.5rem;
  } */

  .my_orders__bids,
  .time-left {
    font-size: 16px;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
}

@media (max-width: 992px) {
  .more-option--box {
    width: auto;
    right: 8.5rem;
    margin-bottom: 3rem;
    background-color: #ffffff;
  }

  .cust_order_img__container {
    width: 25%;
  }
  .cust_order_details_container {
    width: 75%;
  }

  .order_details_container {
    gap: 3rem;
  }

  .order_details_status {
    width: 11rem;
  }

  .cust_order_img {
    width: 100%;
    height: 11rem;
  }
}
/* 
@media (max-width: 900px) {
  .order_details_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }
} */

@media (max-width: 768px) {
  /* .details {
    font-size: 16px;
    color: rgba(106, 125, 159, 1);
    font-weight: 600;
  } */

  .cust_order__details {
    flex-direction: column;
    justify-content: space-between;
  }
  .cust_order_img__container {
    width: 100%;
  }
  .cust_order_details_container {
    width: 100%;
  }

  .more-option--box {
    width: auto;
    right: 7rem;
    margin-bottom: 3rem;
    background-color: #ffffff;
  }
  .cust_order_img {
    width: 100%;
    /* height: 8rem; */
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .order_title {
    font-size: 16px;
  }
  /* .order_area__pr_sq_ft {
    font-size: 14px;
  } */

  /* .cust_order_img img {
    width: 100%;
    height: 30%;
    object-fit: cover;
  } */
}

@media (max-width: 656px) {
  .order_infos__order_status {
    width: 40%;
  }
}

@media (max-width: 576px) {
  .cust_order__details {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .my_orders__bids,
  .time-left {
    font-size: 14px !important;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
  .more-option--box {
    width: auto;
    right: 5rem;
    margin-bottom: 3rem;
    background-color: #ffffff;
  }

  .order_details_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }

  .cust_order_img {
    width: 100%;
    height: 11rem;
    /* object-fit: cover; */
  }

  .order-details-info--container {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .more-option .button-box .view--details,
  .more-option .button-box .delete--order {
    font-size: 14px;
    color: #000000;
  }

  .order-details-info--container {
    width: 100%;
  }
  .order_infos__order_status {
    width: 100%;
  }

  .details {
    font-size: 12px;
    color: rgba(106, 125, 159, 1);
    font-weight: 600;
  }
}
