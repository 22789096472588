@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.steps-section {
  width: auto;
  height: auto;
  margin-top: 7.5rem;
}

.steps {
  background-color: rgba(214, 236, 255, 1);
  width: 20rem;
  height: 3.5rem;
}
.stepCheckes {
  color: rgb(42, 153, 40);
  width: 30px;
}

.steps-name {
  font-size: 18px;
  font-weight: 600;
  color: rgba(66, 71, 78, 1);
  letter-spacing: 1px;
}
.stepChecked {
  width: 25px;
  height: 25px;
  margin-top: 3px;
}

.checked-span {
  width: 20px;
  height: 20px;
}

.mini-catagory-container-checkbox {
  width: 100%;
  margin-bottom: 3px;
  background-color: rgba(244, 243, 247);
}
.stepChecked .css-1edp2za-MuiSvgIcon-root {
  width: 1em;
  height: 1em;
}

@media (max-width: 768px) {
  .steps-section {
    display: none;
  }
}

@media (max-width: 1024px) {
  .steps {
    width: 13.3rem !important;
  }
  .steps-name {
    font-size: 12.5px !important;
    font-weight: 600;
    color: rgba(66, 71, 78, 1);
    letter-spacing: 1px;
  }
  .stepChecked .css-1edp2za-MuiSvgIcon-root {
    width: 0.8em;
    height: 0.8em;
  }
}
@media (max-width: 1200px) {
  .steps {
    width: 17rem;
    height: 3rem;
  }

  .steps-name {
    font-size: 16px;
    font-weight: 600;
    color: rgba(66, 71, 78, 1);
    letter-spacing: 1px;
  }
}
