@font-face {
  font-family: "Gilroy-regular";
  src: url(.././../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.banner_box {
  width: 100%;
  height: 64vh;
  position: relative;
  overflow: hidden;
}

.banner_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill; /* Stretch karega taaki full fit ho */
}

/* .carousel-inner {
  margin-top: 72px;
} */

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-color: #1a1b1e !important;
}

.carousel_home__page .carousel-indicators [data-bs-target] {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  /* box-shadow: 2px 4px 0px #ddd !important;    */
}

.carousel-indicators .active {
  background-color: #1a1b1e !important;
}

.carousel-caption {
  width: 55%;
  left: 7%;
  right: 53%;
  top: 15%;
  padding: 0 !important;
  text-align: left !important;
}

.carousel-caption h3 span {
  font-family: "Gilroy-regular" !important;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 992px) {
  .carousel-caption {
    width: 55%;
    left: 7%;
    right: 53%;
    top: 15%;
    padding: 0 !important;
    text-align: left !important;
  }

  .carousel-caption h3 span {
    font-family: "Gilroy-regular" !important;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .banner_box {
    width: 100%;
    height: 42vh;
    position: relative;
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  .carousel-caption {
    width: 80% !important;
    left: 10% !important;
    right: 10% !important;
    padding: 0 !important;
    text-align: center !important;
  }

  .carousel-caption h3 span {
    font-size: 25px !important;
  }

  .carousel-indicators [data-bs-target] {
    display: none;
  }

  .banner_box {
    width: 100%;
    height: 55vh;
  }
}

@media (max-width: 576px) {
  .banner_box {
    width: 100%;
    height: 28vh;
  }
}
