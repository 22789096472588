@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

/* Adjust the height of the DatePicker */

.personal_verification__container {
  width: 100%;
}

.personal_details_type__section {
  position: relative;
  width: 100%;
}

.personal-details-types {
  width: 100%;
}

/* FormControlLable's lable  */

.personal-details-types .css-ahj2mt-MuiTypography-root {
  font-size: 1rem !important;
}

.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}

.custom-date-picker .MuiInputBase-root {
  width: 100% !important;
  height: 39px;
}

/* Adjust the size of the default icon */
.custom-date-picker .css-i4bv87-MuiSvgIcon-root {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.pan_Name-container .personal_verificationn__checkbox {
  width: 100%;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  width: 100%;
}

@media (max-width: 435px) {
  .personal_verification__container {
    width: 18rem !important;
  }
  .personal_details_type__section {
    width: 18.5em !important;
  }
  .personal-details-types {
    position: relative;
    width: 16.5rem !important;
  }
}

@media (max-width: 568px) {
  .personal_verification__container {
    width: 25rem;
  }
  .personal_details_type__section {
    width: 22em;
  }
  .personal-details-types {
    position: relative;
    width: 25rem;
  }
  .personal-details-types .css-ahj2mt-MuiTypography-root {
    font-size: 0.8rem !important;
  }
}

@media (min-width: 435px) and (max-width: 768px) {
  .personal_verification__container {
    width: 25rem;
  }
  .personal_details_type__section {
    width: 25em !important;
  }
  .personal-details-types {
    position: relative;
    width: 25rem !important;
  }
  .personal-details-types .css-ahj2mt-MuiTypography-root {
    font-size: 0.8rem !important;
  }
  .personal_verificationn__checkbox {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .personal_details_type__section {
    width: 29rem;
  }
  .personal_verificationn__checkbox {
    width: 100%;
  }
}
