/* Dropdown.css */
.dropdown {
  width: 100%;
  position: relative;
  display: inline-block;
  border-radius: 5px;
}

.dropbtn {
  width: 100%;
  background-color: #f0f2f4;
  color: #333;
  padding: 14px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 25px;
  transition: background-color 0.3s ease;
}

.dropbtn.active {
  background-color: #d0dfff;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 270px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 8px;
  border-radius: 4px;
}

.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #e5f2ff;
}

.submenu {
  display: block;
  position: absolute;
  left: 200px;
  top: 0;
  background-color: #ffffff;
  min-width: 270px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 23px;
  border-radius: 4px;
}

.submenu div {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: black;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.submenu div:hover {
  background-color: #e5f2ff;
}

.popup-content {
  border: none !important;
  width: auto !important;
  padding: 0 !important;
  border-radius: 5px !important;
}
/* 
@media (max-width: 768px) {
  .dropbtn {
    width: 100% !important;
  }
} */
