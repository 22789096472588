#cust_product__container {
  width: 100%;
  margin-top: 5rem;
}

.search__bar_container {
  width: 70%;
}

.search_bar {
  border-radius: 2px;
}

.cust_product_search:focus,
.cust_product_search:active,
.cust_product_search:hover {
  border: none;
}

.categories_cust_product_container h4 {
  font-size: 20px;
}

.search__bar_container .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0;
}

@media (max-width: 567px) {
  .search__bar_container {
    width: 100%;
  }

  .categories_cust_product_container h4 {
    font-size: 16px;
  }
}
