.account_overview .overview_heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.63px;
  letter-spacing: 0%;
  color: #000;
}

.overview_info_box .overview-heading {
  color: rgba(106, 125, 159, 1);
  font-weight: 600;
  font-size: 16px;
  line-height: 18.83px;
  letter-spacing: 0%;
}

.overview_info_box .overview-info {
  font-weight: 500;
  font-size: 20px;
  line-height: 23.54px;
  letter-spacing: 0%;
  color: #000;
}

.overview-para {
  font-size: 20px;
  color: #42474e;
  border: 1.5px solid rgba(194, 199, 207, 1);
}

@media (max-width: 756px) {
  .account_overview .overview_heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 24.09px;
    letter-spacing: 0%;
    color: #000;
  }
  .overview-para {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .overview_info_box .overview-heading {
    color: rgba(106, 125, 159, 1);
    font-weight: 600;
    font-size: 14px;
    line-height: 16.83px;
    letter-spacing: 0%;
  }

  .overview_info_box .overview-info {
    font-weight: 500;
    font-size: 16px;
    line-height: 18.54px;
    letter-spacing: 0%;
  }

  .overview-para {
    font-size: 16px;
  }
}
