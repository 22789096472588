.profile__container {
  width: 100%;
  height: auto;
  margin-top: 75px;
  padding: 4rem 6.8rem;
  background-color: rgb(253, 251, 255);
}

.profile_heading,
.back_arrow {
  color: rgba(0, 0, 0, 1);
  font-size: 36px;
  line-height: 42.95px;
  font-weight: 600;
}

.search_categories:hover {
  border-color: #275ea7;
  box-shadow: 0 0 10px rgba(39, 94, 167, 0.5);
}

.search_selected__options {
  background-color: #d6ecff;
  text-align: center;
}

.search_selected__options .search_seleted__title {
  color: #001e2f;
  font-size: 20px;
  line-height: 23.54px;
  font-weight: 500;
}

.service_catalouge {
  font-size: 28px;
  line-height: 33.4px;
  color: rgba(26, 27, 30, 1);
  font-weight: 600;
}

.option {
  padding: 10px !important;
}

.categories_card {
  overflow: hidden;
}

.profile_img {
  width: 10rem;
  height: 10rem;

  object-fit: cover;
  object-position: center;
}

.service_card_title {
  font-size: 24px;
  line-height: 28.63px;
  font-weight: 600;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

/* Dropdown.css */
.dropdown {
  width: 100%;
  position: relative;
  display: inline-block;
  border-radius: 5px;
}

.dropbtn {
  width: 100%;
  background-color: #f0f2f4;
  color: #333;
  padding: 14px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease;
}

.dropbtn.active {
  background-color: #d0dfff;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 270px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 8px;
  border-radius: 4px;
}

.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #e5f2ff;
}

.submenu {
  display: block;
  position: absolute;
  left: 200px;
  top: 0;
  background-color: #ffffff;
  min-width: 270px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 23px;
  border-radius: 4px;
}

.submenu div {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: black;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.submenu div:hover {
  background-color: #e5f2ff;
}

.popup-content {
  border: none !important;
  width: auto !important;
  padding: 0 !important;
  border-radius: 5px !important;
}

.add_service_dropdowpn .css-6zvhx2-MuiGrid2-root {
  justify-content: center;
}

@media (max-width: 992px) {
  .profile__container {
    padding: 4rem 3rem 1px 3rem;
  }
}

@media (max-width: 568px) {
  .profile__container {
    padding: 4rem 1rem 1px 1rem;
  }
  .service_catalouge {
    font-size: 22px;
  }
}
