.service_offered__content .service_offered__labels {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.54px;
  text-align: center;
  color: #001e2f;
  background-color: #d6ecff;
  border-radius: 3px;
}

.business_certificates .business_certificates {
  width: 20rem;
  height: auto;
  border-radius: 4px;
}
