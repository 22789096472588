.product_details__container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 6.5rem 75px;
}

#products__img_1 .products-small-img {
  width: 7.5rem;
  height: 7.6rem;
}

#products-big__img .products-img {
  width: 23rem;
  height: 24rem;
}
/* #products-big__img .products-img {
  width: 29.125rem;
  width: 31.313rem;
} */

.products_details__data .product_details__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 29.4px;
  color: #000000;
}

.product_details__quantity .details__quantity {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.26px;
  text-align: left;
  color: #6a7d9f;
}

.product_details__raitings .ratings__no {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.26px;
  text-align: left;
  color: #000000;
}

.product_details__overall_mrp .details__overall_mrp {
  font-size: 40px;
  font-weight: 600;
  line-height: 49px;
  text-align: left;
  color: #000000;
}

.product_details__mrp .details__price_for_one,
.product_details__mrp .details__mrp {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.26px;
  text-align: left;
  color: #6a7d9f;
}

.product_details__mrp .details__saved_money {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.41px;
  text-align: left;
  color: #2a9928;
}

.product_details__available_stock .details__available_stock {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.26px;
  text-align: left;
  color: #000000;
}

.quantity_count .quantity_increment,
.quantity_count .quantity_decreament {
  width: 2rem;
  border: 1px solid black;
  height: 2rem;
}

.quantity_count .quantity_input {
  width: 5rem;
  height: 2rem;
  border: 1px solid;
}

.product_details__quantity,
.product_details__raitings,
.product_details__overall_mrp {
  margin-top: 1.5rem;
}

.product_details__mrp {
  margin-top: 1rem;
  gap: 0.5rem;
}

.product_details__buy_cart_btn {
  margin-top: 2.5rem;
}

@media (min-width: 1241px) {
  .product_details__container {
    padding: 4rem 6rem 75px;
  }
  #products-big__img .products-img {
    width: 32.125rem;
    height: 31.313rem;
  }
  #products__img_1 .products-small-img {
    width: 10.75rem;
    height: 9.438rem;
  }

  .product_details__overall_mrp .details__overall_mrp {
    font-size: 40px;
    font-weight: 600;
    line-height: 49px;
    text-align: left;
    color: #000000;
  }
}

@media (max-width: 1240px) {
  .product_details__container {
    padding: 4rem 5rem 75px;
  }
  #products-big__img .products-img {
    width: 26.125rem !important;
    height: 28.313rem !important;
  }
  #products__img_1 .products-small-img {
    width: 9.75rem;
    height: 8.438rem;
  }

  .product_details__quantity,
  .product_details__raitings,
  .product_details__overall_mrp {
    margin-top: 0.8rem !important;
  }

  .product_details__mrp {
    margin-top: 0.5rem !important;
  }

  .product_details__buy_cart_btn {
    margin-top: 2rem !important;
  }
}
@media (min-width: 1024px) and (max-width: 1081px) {
  #products-big__img .products-img {
    width: 23.125rem !important;
    height: 25.313rem !important;
  }
  #products__img_1 .products-small-img {
    width: 7.75rem;
    height: 7.438rem;
  }

  .product_details__quantity .details__quantity {
    font-size: 18px !important;
  }

  .product_details__overall_mrp .details__overall_mrp {
    font-size: 30px !important;
  }

  .product_details__mrp .details__price_for_one,
  .product_details__mrp .details__mrp {
    font-size: 18px !important;
  }

  .product_details__quantity,
  .product_details__raitings,
  .product_details__overall_mrp {
    margin-top: 0.5rem !important;
  }

  .product_details__mrp {
    margin-top: 0.2rem !important;
    gap: 0.2rem !important;
  }

  .product_details__buy_cart_btn {
    margin-top: 1.5rem !important;
  }
}
@media (max-width: 1024px) {
  #products-big__img .products-img {
    width: 23.125rem !important;
    height: 25.313rem !important;
  }
  #products__img_1 .products-small-img {
    width: 7.75rem;
    height: 7.438rem;
  }

  .product_details__quantity .details__quantity {
    font-size: 18px !important;
  }

  .product_details__overall_mrp .details__overall_mrp {
    font-size: 35px !important;
  }

  .product_details__mrp .details__price_for_one,
  .product_details__mrp .details__mrp {
    font-size: 18px !important;
  }
}

@media (max-width: 992px) {
  #products__img_1 .products-small-img {
    width: 6.7rem;
    height: 6.74rem;
  }
}

@media (max-width: 858px) {
  #products-big__img .products-img {
    width: 21.125rem !important;
    height: 23.313rem !important;
  }
  #products__img_1 .products-small-img {
    width: 6rem;
    height: 5.838rem;
  }
}

@media (max-width: 768px) {
  .product_details__container {
    padding: 4rem 4rem 75px;
  }
  #products__img_1 .products-small-img {
    width: 7rem;
    height: 6.738rem;
  }
}

@media (max-width: 648px) {
  #products-big__img .products-img {
    width: 26.125rem !important;
    height: 28.313rem !important;
  }
  #products__img_1 .products-small-img {
    width: 7.7123rem;
    height: 6.738rem;
  }
}

@media (max-width: 576px) {
  .product_details__container {
    padding: 4rem 1rem 75px;
  }
}

@media (max-width: 467px) {
  #products-big__img .products-img {
    width: 20.125rem !important;
    height: 22.313rem !important;
  }
  #products__img_1 .products-small-img {
    width: 5.75rem !important;
    height: 5.438rem !important;
  }
}

@media (max-width: 375px) {
  .products-big__img {
    width: 100%;
    height: auto;
  }

  #products-big__img .products-img {
    width: 100% !important;

    object-fit: cover;
  }
  #products__img_1 .products-small-img {
    width: 100% !important;

    object-fit: cover;
  }
}
