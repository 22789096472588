.degital_sign {
  width: 100%;
  height: 100%;
}

.digital_sign__heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 28.63px;
  color: #000000;
}

.brush__color_picker {
  border-radius: 3px;
}
.brush__color_picker .color_box {
  border-radius: 4px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 5px !important;
}
