@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.auction-content {
  width: 100%;
  padding: 2px;
  background-color: rgba(253, 255, 255, 1);
}

.auction_items img {
  width: 10rem;
  height: 10rem;
  border-radius: none;
}

.content-title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
}

.filter-point {
  color: rgba(0, 30, 47, 1);
  background-color: rgba(214, 236, 255, 1);
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
  padding: 2px 15px;
}

.details {
  font-size: 16px;
  color: rgba(106, 125, 159, 1);
  font-weight: 600;
}

/* .icons-color {
  color: #6a7d9f;
} */

.circle {
  border-radius: 50%;
  border: 3.5px solid rgb(255, 255, 255);
  box-shadow: 0px 0px 5px -2px;
}

.circle-margin {
  margin-left: -10px;
  z-index: 5;
}

.bids,
.hours-left {
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-weight: 500;
}

.bids-box,
.timer {
  background-color: rgba(240, 243, 255, 1);
}

.budget-price {
  font-size: 22px;
  padding: 6px 12px 6px 12px;
  background-color: rgba(232, 248, 225, 1);
  color: rgba(42, 153, 40, 1);
  border-radius: 4px;
  font-weight: 500;
}

.place-bid-button {
  background-color: rgba(19, 101, 175, 1);
  padding: 6px 18px 6px 18px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.pagination li {
  list-style: none;
  margin: 0 5px;
  width: 31px;
  height: 32px;
  text-align: center;
  padding: 0px, 12px, 0px, 12px;
}

.pagination li a {
  text-decoration: none;
  color: rgba(0, 27, 61, 1);
  font-size: 16px;
  line-height: 20px;
}

.pagination li.active a,
.pagination li.active {
  background-color: rgba(208, 223, 255, 1);
  font-weight: bold;
  border-radius: 4px;
}

.avatars_container .css-17o22dy-MuiAvatar-root {
  width: 20px;
  height: 20px;
}

@media (max-width: 1024px) {
  .content-title {
    font-size: 18px;
  }

  .filter-point {
    font-size: 14px;
    margin-left: 10px !important;
  }

  .details {
    font-size: 14px;
    color: rgba(106, 125, 159, 1);
    font-weight: 600;
  }
  .bids,
  .hours-left {
    font-size: 15px;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }

  .budget-price {
    font-size: 19px;
  }
}

@media (max-width: 876px) {
  .details {
    font-size: 13px;
  }

  .bids,
  .hours-left {
    font-size: 13px;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
  .budget-price {
    font-size: 16px !important;
  }
}

@media (max-width: 764px) {
  .auction_items img {
    width: 4rem;
    height: 4rem;
    border-radius: none;
  }

  .budget-price {
    font-size: 14px !important;
  }
  .bids,
  .hours-left {
    font-size: 12px;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
}

@media (max-width: 564px) {
  .bids,
  .hours-left {
    font-size: 10px;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }

  .budget-price {
    font-size: 17px !important;
  }
}

@media (max-width: 350px) {
  .avatars_container .css-17o22dy-MuiAvatar-root {
    width: 16px;
    height: 16px;
  }
}
