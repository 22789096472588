@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}
.orders-box {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 7rem;
}
.heading {
  font-size: 40px;
  font-weight: bold;
}

.order--quotes__button {
  width: 100%;
}

.link {
  color: rgba(106, 125, 159, 1);
  font-weight: 500;
}

@media (max-width: 1200px) {
  .orders-box {
    padding: 4rem 5rem;
  }
}

@media (max-width: 768px) {
  .orders-box {
    padding: 4rem 3rem 4rem 3rem;
  }
}

@media (max-width: 576px) {
  .orders-box {
    padding: 4rem 1.5rem !important;
  }
}
