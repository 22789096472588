/* .profilePicContainer{
   
} */

.carousel-profile {
  width: 100%;
  height: auto;
}

/* .react-multi-carousel-list {
  position: absolute !important;
} */

.profile_account__carousel .react-multiple-carousel__arrow--left {
  left: 0px !important;
  top: 39% !important;
}

.profile_account__carousel .react-multiple-carousel__arrow--right {
  right: 0px !important;
  top: 39% !important;
}
