.customer_account__container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 6.5rem 75px;
}

.profile_heading_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.83px;
  color: #6a7d9f;
}

.cust_name,
.cust_number {
  font-size: 24px;
  font-weight: 600;
  line-height: 28.63px;
  color: #000000;
}

.edit_my_profile {
  display: inline;
}

@media (max-width: 1024px) {
  .customer_account__container {
    padding: 4rem 3.5rem 75px;
  }
}

@media (max-width: 768px) {
  .customer_account__container {
    padding: 4rem 3rem 75px;
  }

  .profile_heading_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.83px;
    color: #6a7d9f;
  }
  .cust_name,
  .cust_number {
    font-size: 18px;
    font-weight: 600;
    line-height: 20.63px;
    color: #000000;
  }
}

@media (max-width: 576px) {
  .customer_account__container {
    padding: 4rem 3.5rem 75px;
  }

  .profile_heading_text {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.83px;
    color: #6a7d9f;
  }
  .cust_name,
  .cust_number {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.63px;
    color: #000000;
  }
  .edit_my_profile {
    display: none;
  }
}

@media (max-width: 456px) {
  .customer_account__container {
    padding: 4rem 1rem 75px;
  }
}
