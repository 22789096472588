#product_carousel .react-multiple-carousel__arrow--left {
  left: 0 !important;
  top: 46% !important;
  background-color: #6a7d9f !important;
  border: 3px solid #fdfbff !important;
}

#product_carousel .react-multiple-carousel__arrow--right {
  right: 0 !important;
  top: 46% !important;
  background-color: #6a7d9f !important;
  border: 3px solid #fdfbff !important;
}

.product__img {
  object-fit: cover;
  object-position: bottom;
}

@media (max-width: 320px) {
  .product__img {
    width: 100%;
    height: 13rem !important;
  }
}
