.service_catelogue__container {
  width: 100%;
}
/* .categories_services__container .categories_heading {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.26px;
    color: #343538;
  } */

.services_img__container {
  width: 100%;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0.62px 2.75px rgba(39, 53, 141, 0.02);
}

.services__img-box {
  position: relative;
  width: 100%;
  height: auto;
}

.services__img {
  width: 100%;
  height: 8rem;
  object-fit: cover;
  object-position: top;
  display: block;
}

.overlay-text {
  position: absolute;
  top: 20%;
  right: -10px;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  text-align: center;
  white-space: nowrap;
}

.result-content__box {
  border-radius: 4px 4px 0 0;
}

.service_catelogue__details h4 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  line-height: 28px;
  text-transform: capitalize;
  font-weight: 600;
}

/* .categories--title {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
} */

.overlay-text .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: rgb(255, 0, 0);
}

.checker {
  background-color: rgba(244, 243, 247, 1);
  border-radius: 4px;
  text-align: center;
  color: #172b4d;
  font-size: 16px;
  font-weight: 600;
}

.service_catelogue__address {
  font-size: 18px;
  color: #6a7d9f;
  line-height: 23.54px;
  font-weight: 500;
}

.categories__ratings .ratings__outof {
  font-size: 24px;
  color: rgb(0, 0, 0);
  line-height: 33.4px;
}

.categories__reviews {
  color: rgba(106, 125, 159, 1);
  font-size: 16px;
  font-weight: 500;
}

.service-catelogue-other-giving {
  color: rgba(23, 43, 77, 1);
  background-color: rgba(214, 236, 255, 1);
  border-radius: 4px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  padding: 5px 4px 4px;
  line-height: 18.83px;
}

.service_catelogue_details_para {
  color: rgba(106, 125, 159, 1);
  font-size: 16px;
  line-height: 18.83px;
}

.service-pare-expand {
  font-size: 16px;
  color: rgba(74, 123, 157, 1);
  line-height: 18.83px;
  border: none;
  text-transform: capitalize;
  background: none;
}

.service_catelogue--time,
.service_catelogue__response {
  color: rgba(66, 71, 78, 1);
  font-size: 14px;
  line-height: 18.83px;
  font-weight: 500;
  padding: 10px;
  background-color: rgba(244, 243, 247, 1);
  border-radius: 4px;
}

@media (max-width: 992px) {
  .service_catelogue--time,
  .service_catelogue__response {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .categories__ratings .ratings__outof {
    font-size: 20px;
    color: rgb(0, 0, 0);
    line-height: 25.4px;
  }

  .service_catelogue__details h4 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }

  .checker {
    background-color: rgba(244, 243, 247, 1);
    border-radius: 4px;
    text-align: center;
    color: #172b4d;
    font-size: 14px;
    font-weight: 600;
  }
}

@media (max-width: 576px) {
  .service_catelogue__details h4 {
    color: rgb(0, 0, 0);
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }

  .categories__ratings .ratings__outof {
    font-size: 14px;
    color: rgb(0, 0, 0);
    line-height: 20.4px;
  }
  .checker {
    background-color: rgba(244, 243, 247, 1);
    border-radius: 4px;
    text-align: center;
    color: #172b4d;
    width: 7rem;
    font-size: 10px;
    font-weight: 600;
  }

  .categories__reviews {
    color: rgba(106, 125, 159, 1);
    font-size: 12px;
    font-weight: 500;
  }
  .service_catelogue__address {
    font-size: 12px;
    line-height: 14px;
  }
}
