@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.cust_auction_container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 75px;
  padding: 4rem 7rem 4rem;
}

.search--box {
  width: 60%;
  margin: 0 auto;
  margin-top: 40px;
}

.search--input:focus {
  outline: none;
  box-shadow: none;
}

input {
  font-size: 20px;
  line-height: 23.54px;
}

.sub--categories__heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 47.72px;
}

.cust_auction_img_container {
  width: 20%;
  height: 6rem;
}

.cust_auction_name_container {
  width: 70%;
}
.cust_auction_next_button {
  width: 10%;
}

.cust_auction_name_container h4 {
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
}

.cust_auction_name_container p {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  color: #fa9b00;
}

#cust_categories__img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}
#cust_categories__img img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1200px) {
  .cust_auction_container {
    padding: 4rem 4.5rem 4rem 4.5rem;
  }
}

@media (max-width: 768px) {
  .cust_auction_container {
    padding: 4rem 3rem 4rem 3rem;
  }

  .cust_auction_img_container {
    width: 20%;
    height: 3rem;
  }

  .cust_auction_name_container {
    width: 70%;
  }
  .cust_auction_next_button {
    width: 10%;
  }

  .cust_auction_name_container h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
  }

  .cust_auction_name_container p {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 576px) {
  .cust_auction_container {
    padding: 4rem 1.5rem !important;
  }

  .cust_auction_img_container {
    width: 20%;
    height: 3rem;
  }

  .cust_auction_name_container {
    width: 70%;
  }
  .cust_auction_next_button {
    width: 10%;
  }
  #cust_auction_category {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    overflow: hidden;
  }
  .cust_auction_name_container h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  .cust_auction_name_container p {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 420px) {
  .cust_auction_img_container {
    width: 15%;
    height: 2rem;
  }

  .cust_auction_name_container {
    width: 75%;
  }
  .cust_auction_next_button {
    width: 10%;
  }
  .cust_auction_name_container h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }
}
