.vertical_line {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-diamond,
.bottom-diamond {
  content: "";
  transform: translateX(-4%) rotate(45deg);
  width: 5px;
  height: 5px;
  background-color: rgba(194, 199, 207, 1);
}

@media (max-width: 768px) {
  .vertical_line {
    display: none;
  }
}
