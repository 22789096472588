.heading_service {
  font-size: 24px;
  line-height: 28.63px;
  color: rgba(52, 53, 56, 1);
  font-weight: 600;
}
.form-select {
  padding-top: 10px; /* Adjust the value as needed */
  padding-bottom: 10px; /* Adjust the value as needed */
  padding-left: 10px;
  padding-right: 10px;
}
