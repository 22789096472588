#wish_list_container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 74px;
  padding: 4rem 6.5rem 75px;
}

.wish_list_image {
  max-width: 20rem;
  max-height: 14rem;
  height: 15rem;
  min-height: 5rem;
  overflow: hidden;
}
.wish_list_image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center;
}

@media (max-width: 1024px) {
  #wish_list_container {
    padding: 4rem 3.5rem 75px;
  }
}

@media (max-width: 768px) {
  #wish_list_container {
    padding: 4rem 3rem 75px;
  }
}

@media (max-width: 576px) {
  #wish_list_container {
    padding: 4rem 1rem 75px;
  }
  .wish_list_image {
    max-width: 100%;
    max-height: 10rem;
    /* height: 10rem; */
    min-height: 10rem;
    overflow: hidden;
  }
}
