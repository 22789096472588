.request_details__container {
  width: 100%;
  height: auto;
  /* padding: 10px 20px; */
}

.request_details__container .request_details__filter_result {
  font-size: 16px;
  font-weight: 600;
  line-height: 18.83px;

  text-align: center;
  background-color: #ffddb8;
}

@media (max-width: 578px) {
  .request_details__container {
    padding: 0;
  }
}
