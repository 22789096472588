@font-face {
  font-family: "Gilroy-regular";
  src: url(../../../public/fonts/Gilroy-Regular.ttf) format("truetype");
}

.gilroy {
  font-family: "Gilroy-regular" !important;
  text-transform: capitalize !important;
}

.headigs__link {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}
